import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as Yup from "yup";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import {
  FormProvider,
  RHFTextField,
  RHFCheckbox,
} from "../../../../components/hook-form";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { format, parse, sub } from "date-fns";
import { set } from "lodash";
import { toast, ToastContainer } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { useNavigate, useParams } from "react-router-dom";
import Page from "../../../../components/Page";
import { getFileUrl } from "../../../../utils/firebaseUtils";
import RHFTextEditor from "../../../../components/hook-form/RHFTextEditor";
import { RotateLoader } from "react-spinners";

const UpdateTemplateTest = () => {
  const navigate = useNavigate();
  const resultOption = [
    {
      id: "manual",
      title: "Manual",
    },
    {
      id: "automatic",
      title: "Automatic",
    },
  ];
  const streamCategory = [
    {
      id: "class",
      title: "School Exams",
    },
    {
      id: "central-exam",
      title: "Central Exams",
    },
    {
      id: "state-exam",
      title: "State Exams",
    },
  ];
  const examOption = [
    {
      id: "manual",
      title: "Exam 1",
    },
    {
      id: "automatic",
      title: "Exam 2",
    },
    {
      id: "automatic",
      title: "Exam 3",
    },
  ];

  const [chapterOptions, setChapterOptions] = React.useState([
    { id: "1", name: "chapter 1", questions: 20 },
    { id: "2", name: "chapter 2", questions: 120 },
    { id: "3", name: "chapter 3", questions: 120 },
  ]);
  const [focus, setFocused] = React.useState(false);
  const [focusTwo, setFocusedTwo] = React.useState(false);
  const [focusThree, setFocusedThree] = React.useState(false);
  const [showStartingDate, setShowStartingDate] = React.useState(false);
  const [showEndingDate, setShowEndingDate] = React.useState(false);
  const [goal, setGoal] = useState("");
  const [showBanner, setShowBanner] = React.useState(false);
  const [val, setVal] = useState(false);
  const [file, setFile] = useState("");
  const [flag, setFlag] = useState(false);
  const [ids, setIds] = useState([]);
  const [cids, setCIds] = useState([]);
  const [selectedResultType, setSelectedResultType] = useState("");
  const [negativeMark, setNegativeMark] = useState("true");
  const [loaded, setLoaded] = useState(false);
  const [category, setCategory] = useState("");
  const [exam, setExam] = useState("");
  const [description, setDescription] = useState("");
  const [subjects, setSubjects] = useState([]);
  const [selSubject, setSelSubject] = useState([]);

  const [isCategoryLoading, setIsCategoryLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [states, setStates] = useState();
  const [boards, setBoards] = useState();
  const [classVal, setClass] = useState(null);
  const [centralExam, setCentralExam] = useState(null);
  const [stateExam, setStateExam] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedBoard, setSelectedBoard] = useState(null);
  const [languages, setLanguages] = useState([]);

  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [selectedMedium, setSelectedMedium] = useState("");

  const [subjectList, setSubjectList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [course, setCourseName] = useState("");
  const [nagetive, setNagetive] = useState("");

  const token = localStorage.getItem("token");

  useEffect(() => {
    // fetchSubjects();
  }, [goal, selectedLanguage]);

  async function fetchSubjects() {
    setLoading(false);
    const res = await fetch(
      `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-subject-by-goal`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          course: selectedCategory,
          language: selectedLanguage,
          class_exam_id: goal,
        }),
      }
    );
    const resSubject = await res.json();
    console.log("This is subject", resSubject);
    const filteredSubjects =
      resSubject?.data?.map((subject) => ({
        id: subject.subject_id,
        name: subject.subject_name,
      })) || [];
    setSubjectList(filteredSubjects);
    setLoading(false);
  }

  useEffect(() => {
    fetchSubjects();
  }, [goal]);

  // #region fetch Chapter acc to subject

  async function fetchChapters(subjectIds) {
    setLoading(true);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-multiple-chapter-by-subject-id`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            // subject_id: ids,
            subject_id: selSubject.join(",").split(","),
            course: selectedCategory,
            exam_id: goal,
          }),
        }
      );
      const resChapter = await res.json();

      console.log(selSubject);

      const filteredChapters = resChapter.data.map((chapter) => ({
        id: chapter.chapter_id,
        name: chapter.name,
        questions: chapter.count,
        subject_name: chapter.subject_name,
      }));
      setCIds(filteredChapters);
      return filteredChapters;
    } catch (error) {
      console.error("Error fetching chapters:", error);
      return [];
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchChapters(selSubject).then((chapters) => {
      setSubjects((prevSubjects) =>
        prevSubjects.map((subject) =>
          subject.subject_id === selSubject
            ? { ...subject, chapterOptions: chapters }
            : subject
        )
      );
    });
    // fetchChapters(selSubject);
  }, [selSubject, goal]);

  const handleCategoryChange = async (event) => {
    const selectedCategoryId = event.target.value;
    console.log(selectedCategoryId);
    console.log(event.target.value);
    setStates(null);
    setBoards(null);
    setSubCategories(null);
    setSelectedBoard(null);
    setSelectedState(null);

    setIsCategoryLoading(true);
    setSelectedCategory(selectedCategoryId);

    const token = localStorage.getItem("token");
    switch (selectedCategoryId) {
      case "central-exam":
        const centralExams = await axios.post(
          `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-central-exams`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setSubCategories(centralExams?.data?.data);
        break;

      case "state-exam":
        const stateList = await axios.post(
          `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-states`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(stateList?.data?.data);
        setStates(stateList?.data?.data);
        break;

      case "class":
        const boardsList = await axios.post(
          `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-boards`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // console.log(boardsList?.data?.data)
        setBoards(boardsList?.data?.data);
        break;

      default:
        break;
    }
    // Fetch subcategories based on the selected category
    setIsCategoryLoading(false);
  };

  const handleStateChange = async (e) => {
    setIsCategoryLoading(true);
    setSelectedState(e.target.value);
    const currentState = e.target.value;
    setSubCategories(null);
    const stateExamsList = await axios.post(
      `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-state-exams`,
      { state_exam_id: currentState }
    );
    console.log(stateExamsList.data);
    setSubCategories(stateExamsList.data?.data);
    setIsCategoryLoading(false);
  };

  const handleBoardChange = async (e) => {
    setIsCategoryLoading(true);
    const currentBoard = e.target.value;
    setSelectedBoard(e.target.value);
    setSubCategories(null);
    const boardExamsList = await axios.post(
      `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-classes`,
      { board_id: currentBoard }
    );
    console.log(boardExamsList.data);
    setSubCategories(boardExamsList.data?.data);
    setIsCategoryLoading(false);
  };

  const handleSubCategoryChange = (e) => {
    setClass(null);
    setStateExam(null);
    setCentralExam(null);

    if (boards) setClass(e.target.value);
    else if (states) setStateExam(e.target.value);
    else setCentralExam(e.target.value);

    console.log("boards", classVal);
    console.log("states", stateExam);
    console.log("central", centralExam);
  };

  //   #region subject

  const handleAdd = () => {
    setSubjects([
      ...subjects,
      {
        id: Date.now(),
        name: "",
        subject_id: [],
        chapter_id: [],
        chapterOptions: [],
      },
    ]);
  };

  const handleDelete = async(id) => {
    setSubjects(subjects.filter((subject) => subject.id !== id));
    const resp = await axios.delete(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/template-subjects/${id}`,

      {  headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },}
      );
      if(resp.status==200){
        toast.success("Subject Deleted Successfully")
      }
      console.log("Deleted ", resp.data.data);
  };
  const handleDeleteChapter = async(id) => {
    setSubjects(subjects.filter((subject) => subject.id !== id));
    const resp = await axios.delete(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/template-chapter/${id}`,

      {  headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },}
      );
      console.log("Deleted ", resp.data.data);
  };


  useEffect(() => {
    setFlag(false);
  }, [flag]);
  const handleChange = (id, key, value) => {
    setSubjects(
      subjects.map((subject) =>
        subject.id === id ? { ...subject, [key]: value } : subject
      )
    );
    if (key === "subject_id") {
      setSelSubject(value);
    }
  };

  const handleChapterChange = (id, chapters) => {
    setSubjects(
      subjects.map((subject) =>
        subject.id === id ? { ...subject, chapter_id: chapters } : subject
      )
    );
  };

  const handleInputChange = (subjectId, chapterName, question_count) => {
    const updatedSubjects = subjects.map((subject) => {
      if (subject.id === subjectId) {
        const updatedChapters = subject.chapter_id.map((chapter) =>
          chapter.id === chapterName ? { ...chapter, question_count } : chapter
        );
        return { ...subject, chapter_id: updatedChapters };
      }
      return subject;
    });
    setSubjects(updatedSubjects);
  };

  //   #endregion

  const LoginSchema = Yup.object().shape({
    name: Yup.string().required("Title is required"),
    // starting_date: Yup.date().required("Starting date is required"),
    // ending_date: Yup.date().required("Ending date is required"),
    duration: Yup.number()
      .required("Duration is required")
      .positive("Duration must be a positive number"),
    mark_each_question: Yup.number()
      .required("Marks per question is required")
      .positive("Marks per question must be a positive number"),
   
  });
  const defaultValues = {
    name: "",
    starting_date: "",
    ending_date: "",
    duration: "",
    mark_each_question: "",
    negative_mark: "",
    negative_marking: "true",
    category: "",
    exam: "",
    subjects: [],
    description: "",
  };
console.log("FIle URL ",file)
  async function fetchSelectedChapters(subjectIds, category, goal) {
    setLoading(true);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-multiple-chapter-by-subject-id`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            subject_id: subjectIds.join(",").split(","),
            // subject_id: [subjectIds.join(",")],

            course: category,
            exam_id: goal,
          }),
        }
      );
      console.log(goal);
      const resChapter = await res.json();
      const filteredChapters = resChapter.data.map((chapter) => ({
        id: chapter.chapter_id,
        name: chapter.name,
        questions: chapter.count,
        subject_name:chapter.subject_name
      }));
      console.log(filteredChapters);
      return filteredChapters;
    } catch (error) {
      console.error("Error fetching chapters:", error);
      return [];
    } finally {
      setLoading(false);
    }
  }

  const [examName, setExamName] = useState("");
  const [boardName, setBoardName] = useState("");
  const [stateExamName, setStateExamName] = useState("");
  const [className, setClassName] = useState("");
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchMockTest() {
      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/mock-test/${templateId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const response = await res.json();
      console.log(response);
      if (response?.response_code === 200) {
        const data = response?.data;
        setGoal(data?.exam_id || data?.class_id);
        // const formattedStartingDate = new Date(data?.starting_date);
        // const formattedEndingDate = new Date(data?.ending_date);
        reset({
          name: data?.title,
          starting_date: data?.starting_date.slice(0, 10),
          ending_date: data?.end_date ? data.end_date.slice(0, 10) : "N/A",
          duration: data?.duration,
          mark_each_question: data?.eachQuestionMarks,
          negative_mark: parseInt(data?.nagetiveMarking),
          negative_marking: data?.negative_marking,
          subCategory: data?.subCategory,
        });
        setSelectedResultType(data?.result_type);
        console.log(data?.result_type);

        setSelectedCategory(data?.course);
     
        setDescription(data?.description);

        setStateExam(data?.state_exam_id);
        setSelectedState(data?.state_exam_id);
        setSelectedBoard(data?.board_id);
        setExamName(data?.exam_name);
        setClass(data?.class_id);
        setCentralExam(data?.exam_id);
        setBoardName(data?.boards_name);
        setStateExamName(data?.state_exam_name);
        setClassName(data?.classes_name);
        setSelectedLanguage(data?.language);
        setSelectedMedium(data?.medium);

        setNegativeMark(data?.nagetiveMarks);
        setNagetive(data?.nagetiveMarking);
        setClassName(data?.classes_name);

        setExam(data?.exam_name);
        setClass(data?.classes_name);
        setCourseName(data?.course);
        setBoards(data?.data?.boards_name);
        setStateExam(data?.state_exam_name);
        const idValue = data?.subjects?.map((e) => e.subject_id);
        setIds(idValue);

        data?.subjects?.map((e) => {});
        // if (selectedCategory) {
        const formattedSubjects = await Promise.all(
          data?.subjects?.map(async (subject) => {
            const chapters = await fetchSelectedChapters(
              subject?.subject_id.split(","),
              data?.course,
              data?.exam_id || data?.class_id
            );
            // console.log(data);
            // console.log(chapters);
            return {
              id: subject?.id,
              name: subject?.subject_name,
              subject_id: subject?.subject_id.split(",").map(Number),
              chapter_id: subject?.chapters.map((chapter) => ({
                _id: chapter?.id,
                id: chapter?.chapter_id,
                question_count: chapter?.question_count,
                subject_name: chapter?.subject_name,

              })),
              chapterOptions: chapters,
            };
          })
        );
        console.log(formattedSubjects);

        setSubjects(formattedSubjects);
        // }
        setFile(data?.image);
        // methods.setValue("name", data?.name);
        // methods.setValue("starting_date", formattedStartingDate);
        // methods.setValue("ending_date", formattedEndingDate);
        // methods.setValue("duration", data?.duration);
        // methods.setValue("mark_each_question", data?.mark_each_question);
        // methods.setValue("negative_mark", data?.negative_mark);
        // methods.setValue("negative_marking", data?.negative_marking);
      }
    }
    fetchMockTest();
  }, []);

  const methods = useForm({
    resolver: yupResolver(LoginSchema),

    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting },
  } = methods;

  const changeHandler = async (event) => {
    const url= await getFileUrl(event.target.files[0])     ;
    setFile(url);
  };

  const handleResultTypeChange = (event) => {
    setSelectedResultType(event.target.value);
  };

  function formatDateToYYYYMMDD(date) {
    if (!date || !(date instanceof Date)) {
      console.error("Invalid date object:", date);
      return null; // Return null or handle as needed
    }

    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const day = String(date.getUTCDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const getChapterName = (selected, subject) => {
    return (
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
        {selected?.map((value) => {
          const chapterName = cids?.find((sub) => sub.id === value)?.name;
          return <Chip key={value} label={chapterName} />;
        })}
      </Box>
    );
  };
  // const renderedValue = useMemo(() => getChapterName(selected, subject), [selected, subject]);
  const { templateId } = useParams();
  const onSubmit = async (data) => {
    const formattedStartingDate = formatDateToYYYYMMDD(data.starting_date);
    const formattedEndingDate = formatDateToYYYYMMDD(data.ending_date);
   
    setLoading(true);
    const payload = {
      title: data.name,
      image: file,
      starting_date: formattedStartingDate,
      ending_date: formattedEndingDate,
      duration: data.duration,
      eachQuestionMarks: data.mark_each_question,

      reult_type: selectedResultType,
      nagetiveMarks: negativeMark,
      nagetiveMarking: nagetive,
      course: selectedCategory,
      state_exam_id: selectedState,
      language: selectedLanguage,
      medium: selectedMedium,
      board_id: selectedBoard,
      exam_id: centralExam || stateExam,
      class_id: classVal,
      subCategory: data?.subCategory,
      mock_test_type: 2,
      description: description,
      subjects: subjects?.map((subject) => {
        return {
          id: subject?.id,
          name: subject?.name,
          subject_id: subject?.subject_id,
          chapter_id: subject?.chapter_id,
        };
      }),
    };
    console.log(payload);

    const token = localStorage.getItem("token");
    const res = await fetch(
      `${process.env.REACT_APP_LIVE_URL_NEW}/admin/update-mock-test/${templateId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      }
    );
    setLoading(false);
    const response = await res.json();
    if (response?.response_code === 200) {
      toast.success("Template Test Updated Successfully");
      setTimeout(() => {
        // window.location.reload();
        navigate("/dashboard/mockTest");
      }, 1000);
    }
    console.log(response);
  };

  const fetchLanguages = async () => {
    const token = localStorage.getItem("token");
    try {
      const languageData = await axios.post(
        `${process.env.REACT_APP_LIVE_URL_NEW}/mobile/user/get-mediums`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (languageData?.status !== 200)
        throw new Error("Failed to fetch languages");

      setLanguages(languageData?.data?.data);
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };

  // Fetch languages when the component mounts
  useEffect(() => {
    fetchLanguages();
  }, []);
  console.log("Subjects  ", subjects);
  setTimeout(() => {
    setFlag(true);
  }, 4000);
  return (
    <Page title="Mock Test">
      <Box sx={{ p: 3, maxWidth: 900, ml: 3 }}>
        <Typography variant="h2" sx={{ mb: 3 }}>
          Update Template Test
        </Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack>
            <Typography variant="h6" marginBottom={"0.5rem"}>
              Test Title
            </Typography>
            <RHFTextField name="name" label="Enter Test Title" />
          </Stack>
          <Stack
            direction={"row"}
            marginTop={"0.5rem"}
            sx={{ width: "100%" }}
            justifyContent={"space-between"}
          >
            <Stack direction="column" sx={{ width: "49%" }}>
              <Typography variant="h6" marginBottom={"0.5rem"}>
                Test Banner
              </Typography>
              <RHFTextField
                name="file"
                error={!file && val}
                onChange={changeHandler}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                helperText="Image with a size of 712x356 pixels, maintaining a 2:1 aspect ratio (jpeg,jpg,png,webp)"
                inputProps={{ accept: "image/*" }}
                InputProps={{
                  classes: {
                    input: "CustomTextField",
                  },
                }}
                label="Choose an Image"
                onClick={() => setShowBanner(true)}
                type={showBanner || focus ? "file" : "text"}
              />
              {!file && (
                <Typography
                  sx={{
                    fontSize: "12px",
                    marginTop: "5px",
                    marginLeft: "10px",
                    color: "red",
                  }}
                ></Typography>
              )}
            </Stack>
            <Stack direction={"column"} sx={{ width: "49%" }}>
              <Typography variant="h6" marginBottom={"0.5rem"}>
                Result Type
              </Typography>
              <RHFTextField
                name="result_type"
                label="Select Result Type"
                sx={{ width: "100%" }}
                select
                value={selectedResultType}
                onChange={handleResultTypeChange}
              >
                {resultOption?.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </RHFTextField>
            </Stack>
          </Stack>
          <Stack direction={"row"} marginTop={"0.5rem"} sx={{ width: "100%" }}>
            {file}
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
            spacing={2}
          >
            <Stack direction="column" sx={{ width: "49%" }}>
              <Typography variant="h6" marginBottom={"0.5rem"}>
                Language
              </Typography>
              <RHFTextField
                name="language"
                disabled
                select
                SelectProps={{
                  native: true,
                }}
                value={selectedLanguage}
                onChange={(e) => setSelectedLanguage(e.target.value)}
              >
                {languages.map((language) => (
                  <option key={language.id} value={language.medium_code}>
                    {language.medium_name}
                  </option>
                ))}
              </RHFTextField>
            </Stack>
            <Stack direction="column" sx={{ width: "49%" }}>
              <Typography variant="h6" marginBottom={"0.5rem"}>
                Medium
              </Typography>
              <RHFTextField
                name="medium"
                select
                SelectProps={{
                  native: true,
                }}
                value={selectedMedium}
                onChange={(e) => setSelectedMedium(e.target.value)}
              >
                {languages.map((language) => (
                  <option key={language.id} value={language.id}>
                    {language.medium_name}
                  </option>
                ))}
                <option value={3}>Both</option>
              </RHFTextField>
            </Stack>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
            spacing={2}
          >
            <Stack direction="column" sx={{ width: "49%" }}>
              <Typography variant="h6" marginBottom={"0.5rem"}>
                Start Date
              </Typography>
              <RHFTextField
                name="starting_date"
                onFocus={() => setFocusedTwo(true)}
                onBlur={() => setFocusedTwo(false)}
                InputProps={{
                  classes: {
                    input: "CustomTextField",
                  },
                }}
                label="Starting Date"
                onClick={() => setShowStartingDate(true)}
                type={showStartingDate || focusTwo ? "date" : "text"}
              />
            </Stack>
            <Stack direction="column" sx={{ width: "49%" }}>
              <Typography variant="h6" marginBottom={"0.5rem"}>
                End Date
              </Typography>
              <RHFTextField
                name="ending_date"
                onFocus={() => setFocusedThree(true)}
                onBlur={() => setFocusedThree(false)}
                InputProps={{
                  classes: {
                    input: "CustomTextField",
                  },
                }}
                label="Ending Date"
                onClick={() => setShowEndingDate(true)}
                type={showEndingDate || focusThree ? "date" : "text"}
              />
            </Stack>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
            spacing={2}
          >
            <Stack direction="column" sx={{ width: "49%" }}>
              <Typography variant="h6" marginBottom={"0.5rem"}>
                Test Duration (in Minutes)
              </Typography>
              <RHFTextField name="duration" label="Enter Duration" />
            </Stack>
            <Stack direction="column" sx={{ width: "49%" }}>
              <Typography variant="h6" marginBottom={"0.5rem"}>
                Marks per Question
              </Typography>
              <RHFTextField name="mark_each_question" label="Enter Marks" />
            </Stack>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
            spacing={2}
          >
            <Stack direction="column" sx={{ width: "49%" }}>
              <Typography variant="h6" marginBottom={"0.5rem"}>
                Negative Marking Available
              </Typography>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="negative_marking"
                onChange={(e) => setNagetive(e.target.value)}
                value={nagetive}
              >
                <FormControlLabel value="1" control={<Radio />} label="Yes" />
                <FormControlLabel value="0" control={<Radio />} label="No" />
              </RadioGroup>
            </Stack>
            {nagetive === "1" && (
              <Stack direction="column" sx={{ width: "49%" }}>
                <Typography variant="h6" marginBottom={"0.5rem"}>
                  Negative Marks Per Question
                </Typography>
                <RHFTextField
                  value={negativeMark}
                  onChange={(e) => setNegativeMark(e?.target?.value)}
                  name="negative_mark"
                  label="Enter Marks"
                />
              </Stack>
            )}
          </Stack>
          <Stack direction={"row"} spacing={3}>
            <RHFTextField
              name="category"
              label="Category"
              sx={{ width: "50%" }}
              // select
              disabled
              value={course}
              // onChange={handleCategoryChange}
            />

            {stateExam && (
              <RHFTextField
                name="states"
                label="State"
                sx={{ width: "50%" }}
                disabled
                value={stateExam}
                // onChange={handleStateChange}
              />
            )}

            {boards && (
              <RHFTextField
                name="boards"
                label="Boards"
                sx={{ width: "50%" }}
                // select
                disabled
                value={boards}
                // onChange={handleBoardChange}
              />
            )}

            <RHFTextField
              disabled
              name="subCategory"
              // label="Subcategory"
              sx={{ width: "50%" }}
              value={exam || className}
            />
          </Stack>
          <Stack>
            <Typography variant="h6" marginBottom={"0.5rem"}>
              Add Subjects
            </Typography>
            {subjects?.map((subject, subjectIndex) => (
              <Paper
                key={subject.id}
                elevation={3}
                sx={{ p: 2, mb: 2, backgroundColor: "transparent" }}
              >
                <Stack direction="row" spacing={2} alignItems="center">
                  <TextField
                    label={`Subject ${subjectIndex + 1} Name`}
                    variant="outlined"
                    value={subject.name}
                    onChange={(e) =>
                      handleChange(subject.id, "name", e.target.value)
                    }
                    fullWidth
                  />
                  <FormControl fullWidth>
                    <InputLabel>Subjects</InputLabel>
                    <Select
                      multiple
                      value={subject.subject_id}
                      onChange={(e) =>
                        handleChange(subject.id, "subject_id", e.target.value)
                      }
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected?.map((value, selectedIndex) => {
                            const subjectName = subjectList.find(
                              (sub) => sub.id === value
                            )?.name;
                            return (
                              subjectName && (
                                <Chip
                                  key={value}
                                  label={`${selectedIndex + 1}. ${subjectName}`}
                                />
                              )
                            );
                          })}
                        </Box>
                      )}
                    >
                      {subjectList?.map((subjectOption, optionIndex) => (
                        <MenuItem
                          key={subjectOption.id}
                          value={subjectOption.id}
                        >
                          {`${optionIndex + 1}. ${subjectOption.name}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <IconButton
                    onClick={() => handleDelete(subject.id)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Stack>
                <Box mt={2}>
                  <FormControl fullWidth>
                    <InputLabel>Chapters</InputLabel>
                    <Select
                      multiple
                      value={subject?.chapter_id?.map((c) => c.id) || []}
                      onChange={async (e) => {
                        const selectedChapterIds = e.target.value;
                           
                        // const deselectedChapters = subject.chapter_id.filter(
                        //   (c) => !selectedChapterIds.includes(c.id)
                        // );




                        const chapter_id = await Promise.all(
                          selectedChapterIds?.map(async (chapterId) => {
                            const existingChapter = subject.chapter_id.find(
                              (c) => c.id === chapterId
                            );
                            const chapterOption = subject.chapterOptions.find(
                              (c) => c.id === chapterId
                            );
                            return (
                              existingChapter || { ...chapterOption, count: "" }
                            );
                          })
                        );
                        handleChapterChange(subject.id, chapter_id);
                      }}
                      renderValue={(selected) => (
                        <Box
                          sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                        >
                          {selected?.map((value, selectedIndex) => {
                            const chapterName = subject.chapterOptions?.find(
                              (sub) => sub.id === value
                            )?.name;
                            return (
                              chapterName && (
                                <Chip key={value} label={chapterName} />
                              )
                            );
                          })}
                        </Box>
                      )}
                    >
                      <Typography
                        direction="row"
                        textAlign="center"
                        alignItems="center"
                        spacing={3}
                        fontSize="20"
                      >
                        <b>
                          {" "}
                          Total Selected Question :{" "}
                          {subject?.chapter_id?.reduce(
                            (total, e) =>
                              (total += Number(
                                e?.question_count ? e?.question_count : 0
                              )),
                            0
                          )}
                        </b>
                      </Typography>
                      {subject?.chapterOptions?.map((chapter, optionIndex) => (
                        <MenuItem key={chapter.id} value={chapter.id}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                            sx={{ width: "100%" }}
                            justifyContent="space-between"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={3}
                            >
                              <Typography>{chapter.name} </Typography>
                              <Typography spacing={2}>
                                ({chapter.subject_name}){" "}
                              </Typography>
                            </Stack>
                            <Stack spacing={2} direction="row">
                              <Typography>({chapter.questions})</Typography>
                              <TextField
                                type="number"
                                label="Count"
                                // sx={{ ml: 2 }}
                                size="small"
                                value={
                                  subject.chapter_id.find(
                                    (c) => c.id === chapter.id
                                  )?.question_count || ""
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    subject.id,
                                    chapter.id,
                                    e.target.value
                                  )
                                }
                                onClick={(e) => e.stopPropagation()} // Prevents Select from closing when clicking inside the TextField
                              />
                             {/* <DeleteIcon color="error"
                              onClick={()=>{
                                // console.log("Chapyer ",chapter)
                                handleDeleteChapter(chapter._id)
                              }}
                              /> */}
                            </Stack>
                             
                          </Stack>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Paper>
            ))}
            <Button
              variant="contained"
              size="small"
              onClick={handleAdd}
              sx={{ mt: 2, py: 0.5, px: 2, width: "10%" }}
            >
              + Add
            </Button>
          </Stack>

          {/*
            <Stack>
              <Typography variant="h6" marginBottom={"0.5rem"}>
                Description
              </Typography>
              <TextareaAutosize
                placeholder="Write Description Here"
                minRows={8}
                style={{
                  background: "transparent",
                  width: "100%",
                }}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Stack>
          */}
          <Stack>
            <RHFTextEditor
              value={description}
              onChange={(value) => setDescription(value)}
              name="description"
              label="Description"
            />
          </Stack>
          <Stack width={"100%"}>
            <LoadingButton
              type="submit"
              variant="contained"
              size="large"
              loading={loading}
              sx={{
                bgcolor: "#e6e6ff",
                color: "black",
                width: "100%",
                marginTop: "5%",
                "&:hover": { background: "#8080ff" },
              }}
            >
              Update Template Test
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Box>
    </Page>
  );
};

export default UpdateTemplateTest;
