import { Box, Grid, Pagination, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Page from "../../../components/Page";
import BatchCard from "./Components/BatchCard";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import "./students.css";
import CustomTable from "./Components/CustomTable";
import StudentCard from "./Components/StudentCard";
import { useParams } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

//Header For Table
const sampleAssignmentHeader = [
  { NO: "Course" },
  { NO: "Score" },
  { NO: "Correct Ans" },
  { NO: "Wrong Ans" },
  { NO: "Attempt Que" },
  { NO: "Attempted On" },
  // { NO: "Total Point" },
  // { NO: "Point" },
  // { NO: "BatchPoint" },
];
const feeBillingHeader = [
  // { NO: "NO" },
  { NO: "Course" },
  { NO: "Score" },
  { NO: "Correct Ans" },
  { NO: "Wrong Ans" },
  { NO: "Attempt Que" },
  { NO: "Attempted On" },
  // { NO: " Order Id" },
  // { NO: "Billing Details" },
  // { NO: "Amount" },
];
const QuizHeader = [
  // { NO: "NO" },
  { NO: "Quiz Name" },
  // { NO: 'Test Name' },
  { NO: "Score" },
  { NO: "Correct Ans" },
  { NO: "Wrong Ans" },
  { NO: "Attempt Que" },
  { NO: "Attempted On" },
];
const TestSeriesHeader = [
  // { NO: "NO" },
  // { NO: "Test Series Name" },
  { NO: "Test Name" },
  { NO: "Score" },
  { NO: "Correct Ans" },
  { NO: "Wrong Ans" },
  { NO: "Attempt Que" },
  { NO: "Attempted On" },
];
const TimerHeader = [
  // { NO: "NO" },
  // { NO: "Timer Title" },
  { NO: "Test Name" },
  { NO: "Score" },
  { NO: "Correct Ans" },
  { NO: "Wrong Ans" },
  { NO: "Attempt Que" },
  // { NO: "Time Duration" },
  { NO: "Attempted On" },
];
const sampleAssignment = [
  {
    sno: 1,
    assignment: "Assignments-1",
    total: 20,
    point: 10,
    batchPoint: "20/20",
  },
  {
    sno: 2,
    assignment: "Assignments-2",
    total: 20,
    point: 10,
    batchPoint: "20/20",
  },
  {
    sno: 3,
    assignment: "Assignments-3",
    total: 20,
    point: 10,
    batchPoint: "20/20",
  },
  {
    sno: 4,
    assignment: "Assignments-4",
    total: 20,
    point: 10,
    batchPoint: "20/20",
  },
];
const FeeBilling = [
  {
    sno: 1,
    assignment: "Invictaa",
    total: "11-11-11",
    point: "SC00234",
    batchPoint: "$500",
  },
  {
    sno: 2,
    assignment: "English",
    total: "12-11-2022",
    point: "SC00234",
    batchPoint: "$500",
  },
];

const iconStyle = {
  background: "#e6e6ff",
  padding: "5px",
  color: "black",
  borderRadius: "50%",
  cursor: "pointer",
};
const StudentProfile = () => {
  //States
  const [valueBatch, setValueBatch] = useState(0);
  const [valueTest, setValueTest] = useState(0);
  const [valuesubscription, setValuesubscription] = useState(0);


  //States
  const [disableNameBatch, setDisableNameBatch] = useState("left");
  const [disableNameTest, setDisableNameTest] = useState("left");
   const [disableNamesubscription, setDisableNamesubscription] =useState("left");
  // const [disableNameBatch, setDisableNameBatch] = useState('none');
  //   const [disableNameTestSeries, setDisableNameTestSeries] = useState('none');

  //States
  const [sliderBatch, setSliderBatch] = useState(0);
  const [sliderTest, setSliderTest] = useState(0);
  const [slidersubscription, setSlidersubscription] = useState(0);

  //Id
  const { data } = useParams();

  //Tabs
  const [valueForTabs, setValueForTabs] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValueForTabs(newValue);
  };
  // for testseries
  const [loading, setLoading] = useState(true);
  const [testSeries, setTestSeries] = useState();
  const [subscription, setSubscription] = useState();

  const [test, setTest] = useState();
  const [chapterWise, setChapterWise] = useState();
  const [mockTest, setMockTest] = useState();
  const [pyq, setPyq] = useState();
  const [practiceTest, setPracticeTest] = useState();

  //Fetching Data
  const [users, usersSet] = useState();
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      const fullResponse = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/view-user/${data}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      setLoading(false);
      usersSet(responseJson?.data);
      setTestSeries(responseJson?.data?.testseries);
      setChapterWise(responseJson?.data?.chapterwise);
      setPracticeTest(responseJson?.data?.practice_test);
      setMockTest(responseJson?.data?.mock_test);
      setTest(responseJson?.data?.test);
      setPyq(responseJson?.data?.pyq);
      setSubscription(responseJson?.data?.subscription)
    }

    fetchUsers();
  }, []);

  //Scroll Logic
  const handleScrollForBatch = (direction) => {
    if (users?.courses.length - 3 == sliderBatch) {
      setDisableNameBatch("right");
    }
    if (sliderBatch == 0) {
      setDisableNameBatch("left");
    }
    if (direction == "left" && sliderBatch > 0) {
      setDisableNameBatch("none");
      setSliderBatch(sliderBatch - 1);
      setValueBatch(valueBatch + 251);
    } else if (
      direction == "right" &&
      sliderBatch < users?.courses.length - 3
    ) {
      setDisableNameBatch("none");
      setSliderBatch(sliderBatch + 1);
      setValueBatch(valueBatch - 251);
    }
  };
  const handleScrollForTest = (direction) => {
    console.log(direction);
    // debugger;
    if (testSeries?.length - 3 == sliderTest) {
      setDisableNameTest("right");
    }
    if (sliderTest == 0) {
      setDisableNameTest("left");
    }
    if (direction == "left" && sliderTest > 0) {
      setDisableNameTest("none");
      setSliderTest(sliderTest - 1);
      setValueTest(valueTest + 251);
    } else if (direction == "right" && sliderTest < testSeries?.length - 3) {
      setDisableNameTest("none");
      setSliderTest(sliderTest + 1);
      setValueTest(valueTest - 251);
    }
  };

  const handleScrollForsubscription = (direction) => {
    console.log(direction);

    if (subscription?.length - 3 === slidersubscription) {
      setDisableNamesubscription("right");
    }
    if (slidersubscription === 0) {
      setDisableNamesubscription("left");
    }
    if (direction === "left" && slidersubscription > 0) {
      setDisableNamesubscription("none");
      setSlidersubscription(slidersubscription - 1);
      setValuesubscription(valuesubscription + 251);
    } else if (
      direction === "right" &&
      slidersubscription < subscription?.length - 3
    ) {
      setDisableNamesubscription("none");
      setSlidersubscription(slidersubscription + 1);
      setValuesubscription(valuesubscription - 251);
    }
  };


  // const handleScrollForBatch = (direction, section) => {
  //     const maxBatchSlider = users?.batchesTakenByUser.length - 3;
  //     const maxTestSeriesSlider = testSeries?.length - 3;

  //     if (direction === 'left' && slider > 0) {
  //       if (section === 'batch') {
  //         setDisableNameBatch('none');
  //       } else if (section === 'testSeries') {
  //         setDisableNameTestSeries('none');
  //       }
  //       setSlider(slider - 1);
  //       setValue(value + 251);
  //     } else if (direction === 'right' && slider < (section === 'batch' ? maxBatchSlider : maxTestSeriesSlider)) {
  //       if (section === 'batch') {
  //         setDisableNameBatch('none');
  //       } else if (section === 'testSeries') {
  //         setDisableNameTestSeries('none');
  //       }
  //       setSlider(slider + 1);
  //       setValue(value - 251);
  //     }
  //   };

  //Test Series
  //Getting Details
  // const [loading, setLoading] = useState(true);
  // const [testSeries, setTestSeries] = useState();
  // const [test, setTest] = useState();
  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   async function fetchUsers() {
  //     const fullResponse = await fetch(
  //       `${process.env.REACT_APP_LIVE_URL}/adminPanel/getTestSeriesPurchased/${data}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     const responseJson = await fullResponse.json();
  //     setTestSeries(responseJson?.data);
  //     const fullResponseTest = await fetch(
  //       `${process.env.REACT_APP_LIVE_URL}/adminPanel/getAttemptedTestAdminPanelSide?userId=${data}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     const responseJsonTest = await fullResponseTest?.json();
  //     setTest(responseJsonTest);
  //     setLoading(false);
  //     if (responseJson?.msg === "not an admin") {
  //       localStorage.removeItem("isLoggedIn");
  //       localStorage.removeItem("details");
  //     }
  //   }

  //   fetchUsers();
  // }, []);

  //Timer
  //Getting Details

  const [timer, setTimer] = useState();
  // const [test, setTest] = useState()
  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   async function fetchUsers() {
  //     const fullResponse = await fetch(
  //       `${process.env.REACT_APP_LIVE_URL}/adminPanel/getTimerByStudentId/${data}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     const responseJson = await fullResponse?.json();
  //     setTimer(responseJson?.data);

  //     setLoading(false);
  //     if (responseJson?.msg === "not an admin") {
  //       localStorage.removeItem("isLoggedIn");
  //       localStorage.removeItem("details");
  //     }
  //   }

  //   fetchUsers();
  // }, []);
  //console.log(timer)

  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          {" "}
          <SyncLoader color=" #8080ff" loading={loading} size={20} />
        </Stack>
      ) : (
        <Page title="Student Profile">
          <Grid
            container
            spacing={1}
            sx={{ height: "100vh", overflow: "hidden" }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              sx={{ height: "calc(100vh - 60px)", overflowY: "auto" }}
            >
              <Stack spacing={2} sx={{ height: "100%" }}>
                {/* Purchased Course Section */}
                <Box>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    pl={1}
                    pr={2}
                  >
                    <Typography variant="h6" sx={{ fontWeight: "600" }}>
                      Purchased Course
                    </Typography>
                  </Stack>
                  <Box sx={{ minWidth: "100%", minHeight: "130px" }}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ minWidth: "100%", minHeight: "130px" }}
                    >
                      <ArrowBackIosOutlinedIcon
                        sx={{
                          ...iconStyle,
                          color: disableNameBatch === "left" ? "" : "",
                        }}
                        onClick={() => handleScrollForBatch("left")}
                      />
                      <Stack
                        direction="row"
                        spacing={3}
                        pl={1}
                        pr={2}
                        pb={1}
                        mt={1}
                        className="BatchScroll"
                        sx={{ minWidth: "93%" }}
                      >
                        {users?.courses?.map((item, i) => (
                          <BatchCard key={i} value={valueBatch} batch={item} />
                        ))}

                        {users?.courses?.length === 0 && (
                          <BatchCard
                            value={valueBatch}
                            batch={null}
                            title="No  Batch"
                          />
                        )}
                      </Stack>
                      <ArrowForwardIosOutlinedIcon
                        sx={{
                          ...iconStyle,
                          color: disableNameBatch === "right" ? "" : "",
                        }}
                        onClick={() => handleScrollForBatch("right")}
                      />
                    </Stack>
                  </Box>
                </Box>

                {/* Purchased Test Series Section */}
                <Box>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    pl={1}
                    pr={2}
                  >
                    <Typography variant="h6" sx={{ fontWeight: "600" }}>
                      Purchased Test Series
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ minWidth: "100%", minHeight: "130px" }}
                  >
                    <ArrowBackIosOutlinedIcon
                      sx={{
                        ...iconStyle,
                        color: disableNameTest === "left" ? "" : "",
                      }}
                      onClick={() => handleScrollForTest("left")}
                    />
                    <Stack
                      direction="row"
                      spacing={3}
                      pl={1}
                      pr={2}
                      pb={1}
                      mt={1}
                      className="BatchScroll"
                      sx={{ minWidth: "93%" }}
                    >
                      {testSeries?.map((item, i) => (
                        <BatchCard
                          key={i}
                          value={valueTest}
                          batch={item}
                          title={item?.description}
                        />
                      ))}
                      {testSeries?.length === 0 && (
                        <BatchCard
                          value={valueTest}
                          batch={null}
                          title="No Test Series"
                        />
                      )}
                    </Stack>
                    <ArrowForwardIosOutlinedIcon
                      sx={{
                        ...iconStyle,
                        color: disableNameTest === "right" ? "" : "",
                      }}
                      onClick={() => handleScrollForTest("right")}
                    />
                  </Stack>
                </Box>

                {/* Repeat Purchased Course Section */}
                <Box>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    pl={1}
                    pr={2}
                  >
                    <Typography variant="h6" sx={{ fontWeight: "600" }}>
                      Purchased Subscription
                    </Typography>
                  </Stack>
                  <Box sx={{ minWidth: "100%", minHeight: "130px" }}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      sx={{ minWidth: "100%", minHeight: "130px" }}
                    >
                      <ArrowBackIosOutlinedIcon
                        sx={{
                          ...iconStyle,
                          color:
                            disableNamesubscription === "left"
                              ? "gray"
                              : "black",
                        }}
                        onClick={() => handleScrollForsubscription("left")}
                      />
                      <Stack
                        direction="row"
                        spacing={3}
                        pl={1}
                        pr={2}
                        pb={1}
                        mt={1}
                        className="BatchScroll"
                        sx={{ minWidth: "93%" }}
                      >
                        {subscription?.map((item, i) => (
                          <BatchCard
                            key={i}
                            value={valuesubscription}
                            batch={item}
                          />
                        ))}
                        {subscription?.length === 0 && (
                          <BatchCard
                            value={valuesubscription}
                            batch={null}
                            title="No subscription"
                          />
                        )}
                      </Stack>
                      <ArrowForwardIosOutlinedIcon
                        sx={{
                          ...iconStyle,
                          color:
                            disableNamesubscription === "right"
                              ? "gray"
                              : "black",
                        }}
                        onClick={() => handleScrollForsubscription("right")}
                      />
                    </Stack>
                  </Box>
                </Box>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              sx={{ height: "calc(100vh - 60px)", overflowY: "auto" }}
            >
              <StudentCard role="Student" detail={users?.user} />
            </Grid>
          </Grid>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={valueForTabs}
                onChange={handleChange}
                variant="fullWidth"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
              >
                <Tab label="Chapter Wise" {...a11yProps(0)} />
                <Tab label="Mock Test" {...a11yProps(1)} />
                <Tab label="PYQ" {...a11yProps(2)} />
                <Tab label="Practice Test" {...a11yProps(3)} />
                <Tab label="Test" {...a11yProps(4)} />
              </Tabs>
            </Box>
            <TabPanel value={valueForTabs} index={0}>
              <Box>
                <Typography pl={1} variant="h6" sx={{ fontWeight: "600" }}>
                  Chapter Wise
                </Typography>
                <CustomTable
                  sampleAssignment={sampleAssignment}
                  sampleAssignmentHeader={TestSeriesHeader}
                  dataArray={chapterWise}
                  use="ChapterWise"
                />
              </Box>
            </TabPanel>
            <TabPanel value={valueForTabs} index={1}>
              <Box>
                <Typography
                  mt={1}
                  pl={1}
                  variant="h6"
                  sx={{ fontWeight: "600" }}
                >
                  Mock Test
                </Typography>
                <CustomTable
                  sampleAssignment={sampleAssignment}
                  sampleAssignmentHeader={QuizHeader}
                  dataArray={mockTest}
                  use="MockTest"
                />
              </Box>
            </TabPanel>
            <TabPanel value={valueForTabs} index={2}>
              <Box>
                <Typography pl={1} variant="h6" sx={{ fontWeight: "600" }}>
                  PYQ
                </Typography>
                <CustomTable
                  sampleAssignment={sampleAssignment}
                  sampleAssignmentHeader={sampleAssignmentHeader}
                  dataArray={pyq}
                  use="PYQ"
                />
              </Box>
            </TabPanel>
            <TabPanel value={valueForTabs} index={3}>
              <Box>
                <Typography
                  mt={1}
                  pl={1}
                  variant="h6"
                  sx={{ fontWeight: "600" }}
                >
                  Practice Test
                </Typography>
                <CustomTable
                  sampleAssignment={FeeBilling}
                  sampleAssignmentHeader={feeBillingHeader}
                  dataArray={practiceTest}
                  use="PracticeTest"
                />
              </Box>
            </TabPanel>
            <TabPanel value={valueForTabs} index={4}>
              <Box>
                <Typography
                  mt={1}
                  pl={1}
                  variant="h6"
                  sx={{ fontWeight: "600" }}
                >
                  Test
                </Typography>
                <CustomTable
                  sampleAssignment={FeeBilling}
                  sampleAssignmentHeader={TimerHeader}
                  dataArray={test}
                  use="test"
                />
              </Box>
            </TabPanel>
          </Box>
        </Page>
      )}
    </>
  );
};

export default StudentProfile;
