import { filter } from "lodash";
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from "react";
// import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Stack,
  Container,
  Typography,
  Button,
  CircularProgress,
  Tabs,
  Tab,
  MenuItem,
  Select,
  Checkbox,
} from "@mui/material";
// components
// import Page from "../../../components/Page";

// import Scrollbar from "../../../components/Scrollbar";

// import SearchNotFound from "../../../components/SearchNotFound";
// import { UserListHead, UserListToolbar, UserMoreMenu } from "../user";
// mock
// importusers ? from '../_mock/user';
import { useNavigate, useParams } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
//mui icons
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import "./quiz.css";
import "../../quiz/quiz.css";

import { MathJax } from "better-react-mathjax";
import Page from "../../../../components/Page";
// import { useHistory } from 'react-router-dom';
// ------------------------------------------------

const MockTable = () => {
  const access = JSON.parse(localStorage.getItem("detailObject"));

  const [formData, setFormData] = useState({
    question_e: "",
    question_h: "",
    option1_e: "",
    option2_e: "",
    option3_e: "",
    option4_e: "",
    option1_h: "",
    option2_h: "",
    option3_h: "",
    option4_h: "",
    difficulty_level: "",
    correctAns: "",
    answer_e: "",
    answer_h: "",
  });

  // const [questionSelected, setQuestionSelected] = useState(null);
  const [questionsList, setQuestionsList] = useState(null);
  const [testMetaData, setTestMetaData] = useState(null);
  const navigate = useNavigate();
  const [count, setCount] = useState(0)
  const [users, usersSet] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState();
  const [subjectList, setSubjectList] = useState();
  const [subjectName, setSubjectName] = useState();
  const [template_id, setTemplate_id] = useState(0)

  //Getting Id and Name Of quiz
  const { quizId, quizTitle } = useParams();
  const { mockId, mockTitle, countData } = useParams();


  const [updatedQuestion, setupdatedQuestion] = useState({});
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [questionSelected, setQuestionSelected] = useState(null);
  const [mockcount, setMockCount] = useState(0)
  const handleCheckboxChange = (question) => {
    setSelectedQuestions((prevSelected) => {
      const isSelected = prevSelected.includes(question.question_id);
      if (isSelected) {
        // Remove from selected if already selected
        return prevSelected.filter((id) => id !== question.question_id);
      } else {
        // Add to selected if not already selected
        return [...prevSelected, question.question_id];
      }
    });
  };

  // Function to handle bulk delete
  const handleBulkDelete = async () => {
    const token = localStorage.getItem("token");
    const ids = selectedQuestions;

    const requestBody = {
      question_id: ids,
      subject_id: selectedSubject,
      mock_test_id: Number(quizId),
      subject_template_id: template_id,
      type: "mock_test",
    };

    setLoading(true);
    try {
      await axios.delete(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/remove-questions`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: requestBody,
        }
      );
      setQuestionsList((prev) =>
        prev.filter((q) => !selectedQuestions.includes(q.question_id))
      );
      setSelectedQuestions([]);

      window.location.reload();
    } catch (error) {
      console.error("Error deleting questions:", error);
      alert("Failed to delete questions.");
    }
    setLoading(false);
  };

  const handelInput = (e) => {
    const { name, value } = e.target;

    setupdatedQuestion({
      ...updatedQuestion,
      [name]: value,
    });
  };

  useEffect(() => {
    sessionStorage.clear();
    setCount(countData)

    const token = localStorage.getItem("token");
    async function fetchUsers() {
      try {
        setLoading(true);
        if (
          sessionStorage.getItem(quizId) &&
          sessionStorage.getItem(`metadata_${quizId}`)
        ) {
          const questions = JSON.parse(sessionStorage.getItem(quizId));
          const metadata = JSON.parse(
            sessionStorage.getItem(`metadata_${quizId}`)
          );
          console.log("metadata", metadata);
          setQuestionsList(questions);
          setTestMetaData(metadata);
          setQuestionSelected(questions[0]);
          //setSubjectList(metadata?.subject_id);

          if (
            Array.isArray(metadata?.subject_id) &&
            metadata?.subject_id?.length > 0
          ) {
            //  setSelectedSubject(metadata?.subject_id[0].id);
            //   setSubjectName(metadata?.subject_id[0].name);
          }
          setLoading(false);
        }
        const fullResponse = await fetch(
          `${process.env.REACT_APP_LIVE_URL_NEW}/admin/mock-test/${quizId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const responseJson = await fullResponse.json();
        console.log("THis has to be the responseJson:::", responseJson.data);
        const subjectIds = responseJson?.data?.subject_id;
        if (Array.isArray(subjectIds) && subjectIds.length > 0) {
          //   setSelectedSubject(subjectIds[0].id);

          // setSubjectName(subjectIds[0].name);
        }
        // console.log(responseJson)
        //setSubjectList(subjectIds);
        setOptions(responseJson.data.subjects);
        setMockCount(responseJson.data.count);
        setTemplate_id(responseJson.data.subjects[0].id);

        //! temperorily commented
        // const questions = await axios.post(`https://invictaa.com/app-api/api/frontend/get-quiz-question-by-id`, {
        //   "questionId":responseJson?.data?.questionId
        // })

        // console.log("This has to be the Questions List data ::::::", questions)

        setTestMetaData(responseJson?.data);

        sessionStorage.setItem(
          `metadata_${quizId}`,
          JSON.stringify(responseJson?.data || [])
        );
        setLoading(false);
      } catch (error) {
        console.log("Error :: ", error);
        setLoading(false);
      }
      // addDetails(responseJson.data)
    }

    fetchUsers();
  }, []);

  useEffect(() => {
    sessionStorage.clear();


    const token = localStorage.getItem("token");
    async function f() {
      if (!quizId || !selectedSubject) return;
      const questions = await axios.get(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-mock-by-id?subject_template_id=${template_id}&subject_id=${selectedSubject}&id=${quizId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("get test subject id vala ", questions);
      setQuestionsList(questions?.data?.data?.questions || []);
      setQuestionSelected(questions?.data?.data?.questions[0]);

      sessionStorage.setItem(
        quizId,
        JSON.stringify(questions?.data?.data?.questions)
      );
    }
    f();

    return () => {
      // setSelectedSubject();
    };
  }, [selectedSubject, template_id]);

  // //console.log(quizD)

  const [selectedIdOfQuestion, setSelectedIdOfQuestion] = useState("");
  const [selectedIdOfQuestionOnHover, setSelectedIdOfQuestionOnHover] =
    useState("");
  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   async function fetchUsers() {
  //     const fullResponse = await fetch(
  //       `${process.env.REACT_APP_LIVE_URL}/AdminPanel/getQuizQuestions/${quizId}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     const responseJson = await fullResponse.json();
  //     usersSet(responseJson.data); //seting data of responce to the users variable
  //     setSelectedIdOfQuestion(responseJson.data[0]?._id);
  //     setFormData({
  //       ...formData,
  //       question_e: responseJson.data[0]?.question_title[0].e,
  //       question_h: responseJson.data[0]?.question_title[0].h,
  //       difficulty_level: responseJson.data[0]?.que_level[0].e,
  //       option1_e: responseJson.data[0]?.option1[0].e,
  //       option2_e: responseJson.data[0]?.option2[0].e,
  //       option3_e: responseJson.data[0]?.option3[0].e,
  //       option4_e: responseJson.data[0]?.option4[0].e,
  //       option1_h: responseJson.data[0]?.option1[0].h,
  //       option2_h: responseJson.data[0]?.option2[0].h,
  //       option3_h: responseJson.data[0]?.option3[0].h,
  //       option4_h: responseJson.data[0]?.option4[0].h,
  //       correctAns: responseJson.data[0]?.correctOption,
  //       answer_e: responseJson.data[0]?.answer[0].e,
  //       answer_h: responseJson.data[0]?.answer[0].e,
  //     });
  //     setLoading(false);
  //   }

  //   fetchUsers();
  // }, []);
  // //console.log(users);
  // //console.log("form");
  // //console.log(formData);

  //Delete Question
  const deleteDetails = async (id) => {
    setLoading(true);
    const token = localStorage.getItem("token");

    // await fetch(
    //   `${process.env.REACT_APP_LIVE_URL}/adminPanel/deleteQuizQuestion/${id}`,
    //   {
    //     // await fetch(`https://backend-prod.invictaa.com/api/v1/api/v1/${section}/${req}/${id}`, {
    //     method: "DELETE",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   }
    // ).then((res) => {
    //   setLoading(false);
    //   toast.success("Deleted");

    //   setTimeout(() => window.location.reload(), 1000);
    // });
  };

  const [deleteDisplay, setDeleteDisplay] = useState(false);

  //Update
  const handleUpdate = async () => {
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const data = {
      question_title: [
        {
          e: formData?.question_e,
          h: formData?.question_h,
        },
      ],
      option1: [
        {
          e: formData?.option1_e,
          h: formData?.option1_h,
        },
      ],
      option2: [
        {
          e: formData?.option2_e,
          h: formData?.option2_h,
        },
      ],
      option3: [
        {
          e: formData?.option3_e,
          h: formData?.option3_h,
        },
      ],
      option4: [
        {
          e: formData?.option4_e,
          h: formData?.option4_h,
        },
      ],
      answer: [
        {
          e: formData?.answer_e,
          h: formData?.answer_h,
        },
      ],
      correctOption: formData?.correctAns,
    };
    // const res = await axios.put(
    //   `${process.env.REACT_APP_LIVE_URL}/adminPanel/editQuizQuestion/${selectedIdOfQuestion}`,
    //   data,
    //   config
    // );
    // //console.log(res.msg)

    setTimeout(() => {
      setLoading(false);
      // window.location.reload()
    }, 600);

    window.location.reload();
  };

  // uploading excel file for bulk question import
  const handleUploadExcel = () => {
    document.getElementById("fileInput").click();
    document.querySelector(".mainDiv").style.display = "block";
    setProgress(0);
  };

  const [progress, setProgress] = useState();

  const handleFileUpload = async (event) => {
    const file1 = event.target.files[0];
    const token = localStorage.getItem("token");
    const authToken = token;
    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      onUploadProgress: (data) => {
        //Set the progress value to show the progress bar
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    };
    const formData = new FormData();
    formData.append("file", file1);
    //console.log(file1);
    // const response = await axios.post(
    //   `${process.env.REACT_APP_LIVE_URL}/adminPanel/uploadQuizQuestion/${quizId}`,
    //   formData,
    //   config
    // );
    // if (response.data.status == true) {
    //   toast.success("Question File Uploaded Successfully", {
    //     autoClose: false,
    //   });
    // } else {
    //   toast.error(response.data.message, { autoClose: false });
    // }
    //console.log(response);
    setTimeout(() => window.location.reload(), 3000);
  };

  const [language, setLanguage] = useState(false);

  // function editPerticular(data) {
  //   // const history = ;
  //   const targetUrl = `quiz/editquestion/${quizId}/${selectedIdOfQuestion}/${data}`;
  //   useHistory().push(targetUrl);
  // }

  const [dropdownValue, setDropdownValue] = useState("");
  const [options, setOptions] = useState([]);


  const handleDropdownChange = (event) => {
    setDropdownValue(event.target.value);
    setTemplate_id(event.target.value);
  };


  useEffect(() => {
    sessionStorage.clear();


    const token = localStorage.getItem("token");
    const authToken = token;

    const headers = {
      "content-type": "application/json",
      Authorization: `Bearer ${authToken}`,
    };

    const fetchOptions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_LIVE_URL_NEW}/admin/mock-test-template-subjects-by-id/${template_id}`,
          { headers }
        );
        // setOptions(response.data.data);
        setSubjectList(response.data.data);
        setSelectedSubject(response.data.data[0].id);
        setSubjectName(response.data.data[0].subject_name);

      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    fetchOptions();
  }, [quizId, template_id]);
  useEffect(() => {
    if (selectedSubject) {
      // Simulate loading
      setLoading(true);
      setTimeout(() => {
        setSubjectName(
          subjectList?.filter((sub) => sub.id === selectedSubject)[0].name
        );
        setLoading(false);
      }, 1000); // Simulate API call delay
    }
  }, [selectedSubject]);


  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "70vh" }}
        >
          {" "}
          <SyncLoader color="#8080ff" loading={loading} size={20} />
        </Stack>
      ) : (
        <Page title="Quiz Questions">
          <Container sx={{ position: "absolute", minWidth: "100%" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={3}
            >
              <Typography variant="h3" gutterBottom>
                {quizTitle} Questions {` - ( ${mockcount} )`}
              </Typography>
              {/* <Button
                  variant="contained"
                  sx={{
                    width: "20%",
                    alignSelf: "center",
                    background: "#e6e6ff",
                    color: "black",
                    "&:hover": { background: " #8080ff" },
                  }}
                  onClick={handleUploadExcel}
                >
                  Import From Excel
                </Button> */}
              <input
                type="file"
                id="fileInput"
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
                style={{ display: "none" }}
              />
            </Stack>
            <div className="mainDiv">
              <div className="childDiv" style={{ width: `${progress}%` }}>
                <span>{progress}% </span>
              </div>
            </div>
            <Stack
              direction="row"
              mb={2}
              sx={{
                boxShadow: " 0px 0px 17px #BFBFBF",
                borderRadius: "15px",
                height: "100%",
                background: "#E6E6FF",
              }}
            >
              <Stack
                direction="column"
                sx={{
                  flex: 0.5,
                  height: "100%",
                  bgcolor: "#E6E6FF",
                  color: "black",
                  borderRadius: "15px 0px 0px 15px",
                }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  p={2}
                  sx={{
                    width: "100%",
                    //  borderRight: "2px solid  #8080ff",
                  }}
                >
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <svg
                      width="32"
                      height="28"
                      viewBox="0 0 32 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0 16.8H3.55556V11.2H0V16.8ZM0 28H3.55556V22.4H0V28ZM0 5.6H3.55556V0H0V5.6ZM7.11111 16.8H32V11.2H7.11111V16.8ZM7.11111 28H32V22.4H7.11111V28ZM7.11111 0V5.6H32V0H7.11111Z"
                        fill="black"
                      />
                    </svg>
                    <Typography color="black" sx={{ fontSize: "18px" }}>
                      {testMetaData?.No_of_questions} Questions
                    </Typography>
                  </Stack>
                  {(access?.access?.includes("Add Question") ||
                    access?.access.includes("all")) && (
                      <Stack direction="row">
                        <Button
                          p={3}
                          sx={{
                            bgcolor: "#e6e6ff",
                            color: "black",
                            borderRadius: "50px",
                            "&:hover": { background: " #8080ff" },
                          }}
                          onClick={() => {
                            sessionStorage.clear();
                            navigate(
                              `/dashboard/addMockQuestion/${quizTitle}/${quizId}?testseriesId=${testMetaData?.id}&subName=${subjectName}&subId=${selectedSubject}&template_id=${template_id}`
                            );
                            // setAddQuizSelected(true)
                          }}
                        >
                          + Add Question
                        </Button>
                      </Stack>
                    )}

                  <Typography>{deleteDisplay}</Typography>
                </Stack>

                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={"start"}
                  width={"35rem"}
                  overflow={"scroll"}
                  paddingBottom="1rem"
                  sx={{
                    borderRight: "2px solid #8080ff",
                    // borderBottom: "2px solid #8080ff",
                  }}
                >
                  <Tabs
                    value={selectedSubject}
                    onChange={(e, newValue) => {
                      setSelectedSubject(newValue);
                    }}
                    variant="scrollable"
                    aria-label="scrollable auto tabs example"
                    scrollButtons
                    allowScrollButtonsMobile
                  >
                    {subjectList?.map((sub) => {
                      return (
                        <Tab
                          label={`${sub?.name} (${sub?.count || 0})`}
                          key={sub.id}
                          value={sub.id}
                        />
                      );
                    })}
                  </Tabs>
                  {loading && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  )}
                </Stack>

                <div>
                  {/* Delete Selected Button positioned at the top */}
                  {Array.isArray(selectedQuestions) &&
                    selectedQuestions.length > 0 && (
                      <Button
                        variant="contained"
                        size="small"
                        sx={{
                          background: "#e6e6ff",
                          color: "black",
                          "&:hover": { background: "#FF0000" },
                          padding: "4px 8px",
                          fontSize: "0.75rem",
                          marginBottom: "10px",
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                        onClick={handleBulkDelete}
                      >
                        Delete Selected
                      </Button>
                    )}

                  <Stack
                    direction="column"
                    sx={{
                      width: "100%",
                      height: "calc(100vh - 50px)", // Adjust height to accommodate the button
                      borderRight: "2px solid #8080ff",
                      maxHeight: "fit-content",
                      overflowY: "scroll",
                      borderBottomLeftRadius: "15px",
                      background: "#E6E6FF",
                    }}
                  >
                    {questionsList ? (
                      questionsList.length === 0 ? (
                        <h3
                          style={{
                            width: "full",
                            justifyContent: "center",
                            display: "flex",
                          }}
                        >
                          No Questions
                        </h3>
                      ) : (
                        questionsList.map((questionData, index) => (
                          <Stack
                            spacing={2}
                            p={2}
                            direction="row"
                            style={{
                              cursor: "pointer",
                              backgroundColor:
                                questionSelected === questionData
                                  ? "whitesmoke"
                                  : "transparent",
                              transition: "background-color 0.3s",
                            }}
                            key={questionData?.question_id}
                            onClick={() => setQuestionSelected(questionData)}
                          >
                            <Checkbox
                              checked={
                                Array.isArray(selectedQuestions) &&
                                selectedQuestions.includes(
                                  questionData.question_id
                                )
                              }
                              onChange={() =>
                                handleCheckboxChange(questionData)
                              }
                            />
                            <div style={{ paddingTop: "0.55rem" }}>
                              {index + 1}){" "}
                            </div>
                            <div
                              fontSize="10px"
                              margin="0.5rem"
                              style={{ padding: "0 0.45rem" }}
                            >
                              <MathJax>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: questionData?.question_title,
                                  }}
                                ></div>
                              </MathJax>
                            </div>
                          </Stack>
                        ))
                      )
                    ) : (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          marginTop: "3rem",
                        }}
                      >
                        <CircularProgress
                          size={50}
                          style={{ marginInline: "auto" }}
                        />
                      </div>
                    )}
                  </Stack>
                </div>
              </Stack>

              <Stack
                direction="column"
                spacing={2}
                sx={{
                  flex: 1,
                  height: "110vh",
                  overflowY: "scroll",
                  bgcolor: "#E6E6FF",
                  borderRadius: "0px 15px 15px 0px",
                }}
              >
                <Stack
                  direction="row"
                  p={1.5}
                  alignItems="center"
                  sx={{
                    width: "100%",
                    borderBottom: 1,
                    borderColor: "lightgray",
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    spacing={2}
                    sx={{ flex: 1 }}
                  >
                    <Select
                      value={template_id}
                      onChange={handleDropdownChange}
                      displayEmpty
                      sx={{
                        flex: 1,
                        bgcolor: "#e6e6ff",
                        color: "black",
                        borderRadius: "15px",
                        "& .MuiSelect-select": {
                          padding: "8px 16px",
                        },
                      }}
                    >
                      {options?.map((option) => (
                        <MenuItem key={option?.id} value={option?.id}>
                          {option?.subject_name}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography
                      p={1}
                      sx={{
                        flex: 1,
                        background: "#807FFF",
                        color: "white",
                        borderRadius: "25px",
                        cursor: "pointer",
                        textAlign: "center", // Center the text horizontally
                      }}
                    >
                      Duration: {testMetaData?.duration} min
                    </Typography>
                  </Stack>

                  <Stack
                    direction="row"
                    justifyContent="center"
                    sx={{ flex: 1 }}
                  >
                    <Typography
                      p={1}
                      px={2}
                      sx={{
                        color: "white",
                        background: "#807FFF",
                        borderRadius: "25px",
                        cursor: "pointer",
                      }}
                    >
                      Marks per question : {testMetaData?.eachQuestionMarks}
                    </Typography>
                  </Stack>
                  {/* <Stack  //!language info badge
                      direction="row"
                      justifyContent="space-around"
                      sx={{ flex: 1 }}
                    >
                      <Typography
                        p={1}
                        sx={{
                          color: "white",
                          background: "#807FFF",
                          borderRadius: "100%",
                          width: "45px",
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => setLanguage(!language)}
                      >
                        {language ? "अ" : "A"}
                      </Typography>
                    </Stack> */}
                </Stack>

                {questionSelected ? (
                  <Stack
                    fontSize={"0.9rem"}
                    spacing={1}
                    style={{ width: "45vw", marginLeft: "1.2rem" }}
                  >
                    <Stack style={{ display: "flex", marginBottom: "1rem" }}>
                      <MathJax>
                        <Stack direction="row" spacing={3}>
                          <div style={{ display: "flex" }}>
                            <b>Question:</b>
                          </div>
                          <p
                            className="text-lg"
                            style={{
                              display: "inline",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: questionSelected?.question_title,
                            }}
                          ></p>
                        </Stack>
                      </MathJax>
                    </Stack>

                    <MathJax>
                      <Stack
                        className="Options"
                        direction={"column"}
                        marginY={"2rem"}
                        spacing={3}
                      >
                        <Stack direction="row" spacing={1}>
                          <strong style={{ margin: "0.5rem" }}>Options:</strong>
                          <Stack spacing={4} direction="row" flexWrap={"wrap"}>
                            {questionSelected?.options.map((option, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    width: "14rem",
                                    padding: "1rem",
                                    margin: "0.5rem",
                                    background: "white",
                                    borderRadius: "5px",
                                    height: "fit-content",
                                    minHeight: "5rem",
                                    boxShadow: " 0px 0px 17px #807FFF",
                                  }}
                                >
                                  {String.fromCharCode(96 + index + 1)}) &nbsp;
                                  {option.option || option?.option_value}
                                </div>
                              );
                            })}
                          </Stack>
                        </Stack>

                        <Stack className="correct Option">
                          <Stack spacing={1}>
                            <strong>Correct Option:</strong>
                            {questionSelected?.options?.map((opt, i) => {
                              if (opt.is_correct === 1)
                                return (
                                  <div key={i}>
                                    {String.fromCharCode(i + 97)}) {opt?.option}{" "}
                                    &nbsp; {opt?.option_value}
                                  </div>
                                );
                            })}
                          </Stack>
                        </Stack>
                      </Stack>
                    </MathJax>
                    <Stack style={{ display: "flex", marginBottom: "1rem" }}>
                      <MathJax>
                        <Stack direction="row" spacing={3}>
                          <div style={{ display: "flex", marginTop: "1rem" }}>
                            <b>Explanation:</b>
                          </div>
                          <p
                            className="text-lg"
                            style={{
                              display: "inline",
                            }}
                            dangerouslySetInnerHTML={{
                              __html: questionSelected?.description_text,
                            }}
                          ></p>
                        </Stack>
                      </MathJax>
                    </Stack>
                  </Stack>
                ) : (
                  <>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        marginTop: "3rem",
                      }}
                    >
                      {/* <CircularProgress
                          size={50}
                          style={{ marginInline: "auto" }}
                        /> */}
                    </div>
                  </>
                )}
              </Stack>
            </Stack>
          </Container>
        </Page>
      )}
    </>
  );
};

export default MockTable;
