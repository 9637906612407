import React, { useEffect, useState } from 'react';
import {
    Tabs, Tab, Box, Typography, TextField, Button, Modal, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    Radio,
    Chip, Autocomplete,
    Stack,
} from '@mui/material';
import axios from 'axios';
import { getFileUrl } from '../../../utils/firebaseUtils';
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import RHFTextEditor from '../../../components/hook-form/RHFTextEditor';
import { FormProvider, useForm } from 'react-hook-form';
import { RHFTextField } from '../../../components/hook-form';
const token = localStorage.getItem("token");
const config = {
    headers: {
        // 'content-type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
    },
};
const ExamGroups = () => {
    const access = JSON.parse(localStorage.getItem('detailObject')) || {};
    const role = localStorage.getItem('role') || '';
    const accessArr = access?.access_level?.split(',');
    const accessToContent = access?.accessToContent || [];

    const [tabValue, setTabValue] = useState(0); // Default to "All Notifications"
    const [notifications, setNotifications] = useState([]);
    const [exams, setExams] = useState([]);
    const [classes, setClasses] = useState([]);
    const [file, setFile] = useState();
    const [examIds, setExamIds] = useState([])
    const [classIds, setClassIds] = useState([])
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        image: '',
        group_type: '',
        class_ids: [],
        status: 1,
        exam_ids: [],
    });
    const [editData, setEditData] = useState({

    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [mapOpen, setMapOpen] = useState(false)
    const [deleteId, setDeleteId] = useState(null);
    const [page, setPage] = useState(0); // Default page number
    const [rowsPerPage, setRowsPerPage] = useState(5); // Default 5 rows per page

    // Dummy notifications


    // Fetch notifications from API
    const fetchNotifications = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/exam-group-map`, config); // Replace with your API URL
            setNotifications(response.data.data);
            toast.success(response.data.message)

        } catch (error) {
            console.error('Error fetching Group:', error);
        }
    };
    const getExams = async () => {
        const response = await axios.get(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/exams`, config); // Replace with your API URL
        setExams(response.data.data);
        // toast.success(response.data.message)
    }
    const getClasses = async () => {
        const response = await axios.get(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/classes`, config); // Replace with your API URL
        setClasses(response.data.data);
        // toast.success(response.data.message)
    }
    useEffect(() => {
        getExams()
        getClasses()
        // Uncomment the line below to fetch notifications from your API
        fetchNotifications();
    }, []);

    // Handling tab change
    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    // Handling input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    // Handling page change for pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // Handling rows per page change for pagination
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset page to 0 when rows per page changes
    };

    // Add notification form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/create-exam-group-map`, { ...formData, description: formData.description, image: file }, config); // Replace with your API URL

            setFormData({
                title: '',
                description: '',
                image: '',
                group_type: '',
                class_ids: [],
                exam_ids: [],
            });
            toast.success(response.data.message)
            setTabValue(0); // Switch to "All Notifications" after adding
            fetchNotifications();
        } catch (error) {
            toast.error(error.message)

            console.error('Error adding Group:', error);
        }
    };

    // Open modal to edit notification
    const handleEdit = (group) => {
        setEditData(group);
        setExamIds(group.exam_ids)
        setClassIds(group.class_ids)
        setIsModalOpen(true);
    };
    const handleMap = (group) => {
        setExamIds(group.exam_ids?.map(id => Number(id)));
        setClassIds(group.class_ids?.map(id => Number(id)));
        
        setEditData(group)

        setMapOpen(true)
   
    };

    // Handle modal close
    const handleModalClose = () => {
        setIsModalOpen(false);
        setEditData(null);
    };
    const handleMapModalClose = () => {
        setMapOpen(false);
        setEditData(null);
    };

    // Update notification
    const handleUpdate = async () => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/exam-group-map/${editData.id}`, editData, config); // Replace with your API URL
            handleModalClose();
            toast.success(response.data.message)
            fetchNotifications();

        } catch (error) {
            toast.error(error.message)

            console.error('Error updating group:', error);
        }
    };
    const mapHandler = async () => {
        try {
            setMapOpen(false);
            const response = await axios.put(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/exam-group-mapping/${editData.id}`, { exam_ids: examIds, class_ids: classIds }, config); // Replace with your API URL
            handleModalClose();
            toast.success(response.data.message)
            setExamIds([]);
            setClassIds([]);
            fetchNotifications();

        } catch (error) {
            toast.error(error.message)

            console.error('Error updating group:', error);
        }
    };

    // Delete notification
    const handleDelete = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/exam-group-map/${deleteId}`, config); // Replace with your API URL

            setDeleteId(null);
            toast.success("Group Deleted Success");
            fetchNotifications();

        } catch (error) {
            toast.error(error.message);

            console.error('Error deleting Group:', error);
        }
    };
    const changeHandler = async (event) => {

        setFile(await getFileUrl(event.target.files[0]));

    }

    const methods = useForm();
    console.log(
    "Data ",examIds,classIds
    )
    const [mapIndex, setMapIndex] = useState(0)
    const handleTabChange = (event, newValue) => {
        setMapIndex(newValue);
    };

    const handleExamSelectChange = (newValue) => {
        setExamIds(newValue.map((item) => item.id)); // Update `examIds` with selected exam IDs
    };

    const handleClassSelectChange = (newValue) => {
        setClassIds(newValue.map((item) => item.id)); // Update `classIds` with selected class IDs
    };


    return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: "space-between", px: 4, mt: 4 }}>
            <ToastContainer position="bottom-center" />
            <Tabs
                value={tabValue}
                onChange={handleChangeTab}

                sx={{
                    width: '50%',
                    '& .MuiTabs-flexContainer': {
                        justifyContent: 'center',
                    },
                    '& .MuiTab-root': {
                        width: '50%',
                    },
                }}
            >
                {(role === 'Admin' || accessToContent?.includes('All Notifications')) && (
                    <Tab label="All Exam Groups" />
                )}
                {(role === 'Admin' || accessToContent?.includes('All Notifications')) && (
                    <Tab label="Add Exam Group" />
                )}


            </Tabs>

            <TabPanel value={tabValue} index={0}>
                {notifications?.length === 0 ? (
                    <Typography sx={{ textAlign: 'center', mt: 3 }}>No Group available.</Typography>
                ) : (
                    <TableContainer component={Paper} sx={{ width: '100%', margin: '20px auto' }}>
                        <Table aria-label="notification table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Title</TableCell>
                                    <TableCell>Image</TableCell>
                                    <TableCell>Description</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Exam Mapping</TableCell>
                                    {
                                        (role == 'Admin' || accessArr?.includes('write') || accessArr?.includes('delete')) &&
                                        <TableCell>Actions</TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {notifications?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((group) => (
                                    <TableRow key={group.id}>
                                        <TableCell>{group?.id}</TableCell>
                                        <TableCell>{group?.title}</TableCell>
                                        <TableCell align="left">

                                            <img
                                                src={group?.image}
                                                alt={group?.title}
                                                style={{ width: "100px", height: "50px" }}
                                            />

                                        </TableCell>
                                        <TableCell>
                                            <div dangerouslySetInnerHTML={{ __html: group?.description?.slice(0, 50) + "..." }}></div>
                                        </TableCell>
                                        <TableCell color={group?.status == 1 ? "success" : "error"}>{group?.status == 1 ? "Published" : "Draft"}</TableCell>

                                        <TableCell> <Button color='success' variant="outlined" onClick={() => {
                                            handleMap(group)
                                        }}>Map Exams</Button> </TableCell>
                                        <TableCell>
                                            {
                                                (role == 'Admin' || accessArr?.includes('write')) && <Button variant="outlined" onClick={() => handleEdit(group)}>
                                                    Edit
                                                </Button>
                                            }
                                            {
                                                (role == 'Admin' || accessArr?.includes('delete')) && <Button variant="outlined" color="error" sx={{ ml: 1 }} onClick={() => setDeleteId(group.id)}>
                                                    Delete
                                                </Button>
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {/* Pagination */}
                        <TablePagination
                            component="div"
                            count={notifications?.length}
                            page={page}
                            onPageChange={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            rowsPerPageOptions={[5, 10, 25]}
                        />
                    </TableContainer>
                )}
            </TabPanel>

            {(role === 'Admin' || accessToContent?.includes('All Notifications')) && (
                <TabPanel value={tabValue} index={1}>
                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3, width: '100%', margin: '0 auto' }}>

                        {/* Title Field */}
                        <TextField
                            label="Title"
                            name="title"
                            value={formData.title}
                            onChange={handleInputChange}
                            fullWidth
                            margin="normal"
                        />

                        {/* Description Field
                        <FormControl component="fieldset" sx={{ mt: 2 }}>
                            <FormLabel component="legend">Group Type</FormLabel>
                            <RadioGroup
                                row
                                name="group_type"
                                value={formData.group_type}
                                onChange={handleInputChange}
                            >
                                <FormControlLabel value="exam" control={<Radio />} label="Exam" />
                                <FormControlLabel value="class" control={<Radio />} label="Class" />
                            </RadioGroup>
                        </FormControl>

                     
                        {formData.group_type == 'exam' && (
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="select-multiple-label">Select Items</InputLabel>
                                <Select
                                    labelId="select-multiple-label"
                                    id="select-multiple"
                                    multiple
                                    value={formData.exam_ids}
                                    onChange={(e) => setFormData({ ...formData, exam_ids: e.target.value })}
                                    renderValue={(selected) => (
                                        selected.map((e, index) => {
                                            const selectedOption = exams.find((i) => i.id == e);
                                            return selectedOption ? <Chip key={index} label={selectedOption.name} /> : null;
                                        })
                                    )}
                                >
                                    {exams?.map((option, index) => (
                                        <MenuItem key={index} value={option.id}>
                                            <Checkbox checked={formData.exam_ids.indexOf(option.id) > -1} />
                                            <ListItemText primary={option.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                        {formData.group_type == 'class' && (
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="select-multiple-label">Select Items</InputLabel>
                                <Select
                                    labelId="select-multiple-label"
                                    id="select-multiple"
                                    multiple
                                    value={formData.class_ids}
                                    onChange={(e) => setFormData({ ...formData, class_ids: e.target.value })}
                                    renderValue={(selected) => (
                                        selected.map((e, index) => {
                                            const selectedOption = classes.find((i) => i.id == e);
                                            return selectedOption ? <Chip key={index} label={selectedOption?.class_name} /> : null;
                                        })
                                    )}
                                >
                                    {classes?.map((option, index) => (
                                        <MenuItem key={index} value={option.id}>
                                            <Checkbox checked={formData.class_ids.indexOf(option.id) > -1} />
                                            <ListItemText primary={option.class_name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}


                       */}
                        <FormProvider {...methods}>
                            <RHFTextEditor
                                onChange={(value) => setFormData({ ...formData, description: value })}
                                name="description"
                                value={formData.description}
                                label="Description"
                            />
                        </FormProvider>

                        {/* Image Upload */}
                        <TextField
                            label="Image URL"
                            name="image"
                            type="file"
                            onChange={changeHandler}
                            fullWidth
                            margin="normal"
                        />
                        <Select
                            value={formData.status}
                            onChange={handleInputChange}
                            fullWidth
                        >
                            <MenuItem value={1}>Publish</MenuItem>
                            <MenuItem value={0}>Draft</MenuItem>

                        </Select>
                        {/* Submit Button */}
                        <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
                            Add Group
                        </Button>
                    </Box>
                </TabPanel>

            )}


            {/* Edit Modal */}
            <Modal open={isModalOpen} onClose={handleModalClose}>
                <Box
                    sx={{
                        width: 700,
                        maxHeight: '80vh',
                        overflowY: "auto",
                        padding: 4,
                        margin: '10% auto',
                        backgroundColor: 'white',
                        borderRadius: 2,
                        display: 'flex',
                        flexDirection: 'column',

                    }}
                >
                    <Typography variant="h6">Edit Exam Group</Typography>

                    {/* Title Field */}
                    <TextField
                        label="Title"
                        name="title"
                        value={editData?.title || ''}
                        onChange={(e) => setEditData({ ...editData, title: e.target.value })}
                        fullWidth
                        margin="normal"
                    />

                    {/* Description Field */}
                    <FormProvider {...methods}>
                        <RHFTextEditor
                            onChange={(value) => setEditData({ ...editData, description: value })}
                            name="description"
                            value={editData?.description || ''}
                            label="Description"
                        />
                    </FormProvider>

                    {/* Image Upload */}
                    <Typography sx={{ mt: 4 }}>Image URL: {editData?.image}</Typography>
                    <TextField
                        label="Image URL"
                        name="image"
                        type="file"
                        onChange={changeHandler}
                        fullWidth
                        margin="normal"
                    />

                    {/* Status Select */}
                    <TextField
                        select
                        label="Status"
                        name="status"
                        value={editData?.status}
                        onChange={(e) => setEditData({ ...editData, status: e.target.value })}
                        fullWidth
                        margin="normal"
                    >
                        <MenuItem value={1}>Published</MenuItem>
                        <MenuItem value={0}>Draft</MenuItem>
                    </TextField>

                    {/* Centered Button with Spacing */}
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                        <Button variant="contained" color="primary" onClick={handleUpdate}>
                            Update
                        </Button>
                    </Box>
                </Box>
            </Modal>

            {/* Map Modal */}
            <Modal open={mapOpen} onClose={handleMapModalClose}>
                <Box
                    sx={{
                        width: 700,

                        height: "50vh",
                        overflowY: "auto",
                        padding: 4,
                        margin: '10% auto',
                        backgroundColor: 'white',
                        borderRadius: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: "center"

                    }}
                >
                    <Typography variant="h6">Map Exams and Classes</Typography>

                    <FormControl fullWidth margin="normal">
                        {/* <InputLabel>Select Items</InputLabel> */}

                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} >
                            <Tabs sx={{ display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center' }} value={mapIndex} onChange={handleTabChange} aria-label="Class and Exam tabs">
                                <Tab label="Class" sx={{ width: "50%" }} />
                                <Tab label="Exam" sx={{ width: "50%" }} />
                            </Tabs>
                        </Box>

                        {/* Class Tab */}
                        {mapIndex === 0 && (
                            <Box sx={{ marginTop: 4 }}>
                                <Typography variant="subtitle1" sx={{ marginLeft: 2 }}>
                                    Select Classes
                                </Typography>
                                <Autocomplete
                                    multiple
                                    sx={{ marginTop: 3 }}
                                    id="class-select"
                                    options={classes || []}
                                    value={classes?.filter((item) => classIds.includes(item.id)) || []} // Filter classes based on `classIds`
                                    onChange={(event, newValue) => handleClassSelectChange(newValue)}
                                    disableCloseOnSelect
                                    isOptionEqualToValue={(option, value) => option.id == value.id} // Ensure option matching by `id`
                                    getOptionLabel={(option) => option?.class_name} // Display the `class_name`
                                    renderInput={(params) => <TextField {...params} label="Classes" />}
                                    renderOption={(props, option, { selected }) => (
                                        <Box component="li" {...props}>
                                            <Checkbox
                                                checked={selected} // Automatically uses `selected` to track checked state
                                            />
                                            <ListItemText primary={option?.class_name} />
                                        </Box>
                                    )}
                                    renderTags={(selected) =>
                                        selected.map((classItem, index) => (
                                            <Chip key={index} label={classItem?.class_name} />
                                        ))
                                    }
                                />

                            </Box>
                        )}

                        {/* Exam Tab */}
                        {mapIndex === 1 && (
                            <Box sx={{ marginTop: 4 }}>
                                <Typography variant="subtitle1" sx={{ marginLeft: 2 }}>
                                    Select Exams
                                </Typography>
                                <Autocomplete
                                    multiple
                                    sx={{ marginTop: 3 }}
                                    id="exam-select"
                                    options={exams || []}
                                    value={exams?.filter((item) => examIds.includes(item.id)) || []} // Filter exams based on `examIds`
                                    onChange={(event, newValue) => handleExamSelectChange(newValue)}
                                    disableCloseOnSelect
                                    isOptionEqualToValue={(option, value) => option.id == value?.id} // Ensure option matching by `id`
                                    getOptionLabel={(option) => option?.name} // Display the `name`
                                    renderInput={(params) => <TextField {...params} label="Exams" />}
                                    renderOption={(props, option, { selected }) => {
                                        console.log("Option ",selected); // Check if `selected` is true for expected options
                                        return (
                                            <Box component="li" {...props}>
                                                <Checkbox checked={selected} />
                                                <ListItemText primary={option?.name} />
                                            </Box>
                                        );
                                    }}
                                    renderTags={(selected) => {
                                        console.log("Selected items:", selected);
                                        return selected.map((examItem, index) => (
                                            <Chip key={index} label={examItem?.name} />
                                        ));
                                    }}
                                    
                                />


                            </Box>
                        )}
                    </FormControl>

                    {/* Exam Mapping Button */}
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => mapHandler()}

                        sx={{ mt: 2, width: "200px" }}
                    >
                        Map Exams
                    </Button>
                </Box>
            </Modal>

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={Boolean(deleteId)}
                onClose={() => setDeleteId(null)}
            >
                <DialogTitle>Delete Confirmation</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete this Exam Group?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteId(null)}>Cancel</Button>
                    <Button onClick={handleDelete} color="error">Delete</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

// TabPanel component to handle tab switching
function TabPanel(props) {
    const { children, value, index } = props;

    return (
        <div role="tabpanel" style={{ width: "100%" }} hidden={value !== index}>
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

export default ExamGroups;
