import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  Card,
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/Iconify";
import { FormProvider, RHFTextField } from "../../../components/hook-form";
import { useTheme } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { id } from "date-fns/locale";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
// import "./form.css"
import MultiLevelSelect from "react-select-multi-level";

// import optionsForTeacher from "../../../data/tabArray"
import options from "../../../data/tabArray";

import { getFileUrl } from "../../../utils/firebaseUtils";

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, teacher, theme) {
  return {
    fontWeight:
      teacher.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const label = { inputProps: { "aria-label": "Checkbox demo" } };
export default function AddTeacherForm() {
  // console.log(options)
  const theme = useTheme();
  const navigate = useNavigate();
  const [fetchMessage, setFetchMessage] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [positive, setPositive] = useState(false);
  const [users, usersSet] = useState();
  const [teacher, setteacher] = useState([]);
  const [batch, setbatch] = useState([]);
  const [lecture, setLecture] = useState([]);
  const [accessToContent, setAaccessToContent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("");
  const [accessForTab, setAccesForTab] = useState("read");
  // const [deleteAccess, setDeleteAcess] = useState(false);
  const [selected, setSelected] = useState(false);
  const [file, setFile] = useState();
  const [profilePhotoUrl, setProfilePhotoUrl] = useState();
  const [restrict, setRestrict] = useState("");
  const [focus, setFocused] = useState(false);
  const [pdf, setPdf] = useState(false);
  const [val, setVal] = useState(false);


  const [allAccess, setAllAccess] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [readWrite, setReadWrite] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const [access_level, setAccess_Level] = useState([]);


  useEffect(() => {
    // Update access_level based on individual checkboxes
    const updatedAccess = [];
    if (readOnly) updatedAccess.push("read");
    if (readWrite) updatedAccess.push("write");
    if (deleteAccess) updatedAccess.push("delete");
    setAccess_Level(updatedAccess);
  }, [readOnly, readWrite, deleteAccess]);


  const handleAllAccessChange = (event) => {
    const checked = event.target.checked;
    setAllAccess(checked);
    setReadOnly(checked);
    setReadWrite(checked);
    setDeleteAccess(checked);
  };


  const handleCheckboxChange = (type) => (event) => {
    const checked = event.target.checked;

    // Update the state of the specific checkbox
    switch (type) {
      case "read":
        setReadOnly(checked);
        break;
      case "write":
        if(checked){
          setReadOnly(checked)
          setReadWrite(checked)
        }else{
          setReadWrite(checked)
        }
        break;
      case "delete":
        setDeleteAccess(checked);
        break;
      default:
        break;
    }

    // If any of the individual checkboxes is unchecked, uncheck "All Access"
    if (checked === false && allAccess) {
      setAllAccess(false);
    }
  };






  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required("First name required"),
    // lastName: Yup.string().required('Last name required'),
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    mobileNumber: Yup.string().length(10).required("Mobile No. is required"),
    // password: Yup.string().required("Password is required"),
    // Subject: Yup.string().required('Subject is required'),
    // qualification: Yup.string().required('qualification is required'),
    is_Special: Yup.boolean().default(true),
  });

  const defaultValues = {
    name: "",
    email: "",
    mobileNumber: "",
    password: "",
    // Subject: '',
    // qualification: 'be',
    is_Special: true,
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;
  const [permissionValidation, setPermissionValidation] = useState({
    set: false,
    text: "",
  });
  const onSubmit = async (data) => {
    console.log({
      ...data,
      is_active: String(true),
      subject: teacher ? teacher : "",
      accessToContent: permission,
      qualification: "be",
      access: [accessForTab],
      deleteAccess: String(deleteAccess),
      batch: batch,
      is_Special: String(data?.is_Special),
    });

    // let check =

    if (permissionValidation?.set || true) {
      setLoading(true);
      let token = localStorage.getItem("token");
      try {
        const res = await fetch(
          `${process.env.REACT_APP_LIVE_URL_NEW}/admin/create-admin`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              ...data,
              is_active: String(true),
              subject: teacher ? teacher : "",
              accessToContent: permission,
              qualification: "be",
              access: [accessForTab],
              access_level: access_level,
              deleteAccess: String(deleteAccess),
              batch: batch,
              is_Special: String(data?.is_Special),
              profilePhoto: profilePhotoUrl,
            }),
          }
        );
        const result = await res.json();

        if (result.response_code === 200) {
          toast.success(result?.message);
          setLoading(false);
          reset();
          setTimeout(() => {
            //  window.location.reload();
          }, 1500);
        } else if (result.response_code === 400) {
          toast.error(result?.message);
          setLoading(false);
          // reset()
        } else {
          toast.error(result.msg);
          reset();
          setLoading(false);
          //window.location.href = "/dashboard/staff";
        }
      } catch (error) {
        console.log(error);
      }
    } else setPermissionValidation({ set: false, text: "Mandatory" });
    // navigate('/dashboard', { replace: true });
  };
  //Getting Subjects
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {
      // const res = await fetch(
      //   `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-all-subjects`,
      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );
      const res = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/classroom-subjects`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseJson = await res.json();
      usersSet(responseJson?.data);
      setLoading(false);
    }

    fetchUsers();
  }, []);
  const handleChangeLecture = (event) => {
    const {
      target: { value },
    } = event;
    setLecture(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  const handleChangeBatch = (event) => {
    const {
      target: { value },
    } = event;
    setbatch(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setteacher(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const teacherTabs = [
    {
      name: "All Courses",
    },
    {
      name: "Add Quiz",
    },
    {
      name: "Add Test Series",
    },
    {
      name: "Assignment",
    },
    {
      name: "Help and Support",
    },
    {
      name: "Recorded Video",
    },
    {
      name: "Pathyakram",
    },
    {
      name: "Notes",
    },
    {
      name: "AIR",
    },
    {
      name: "News Clip",
    },
    {
      name: "Teacher Generated Content Of Batch",
    },
  ];

  const handleChangeAccess = (event) => {
    const {
      target: { value },
    } = event;
    setAaccessToContent(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const moderatorAccess = [
    {
      label: "Courses",
      value: ["All Batches", "All Lecture"],
    },
  ];

  // const [showList, setShowList] = useState(false)
  // const [show, setShow] = useState(false)
  // const [showId, setShowId] = useState('')
  const [permission, setPermission] = useState(
    role == "teacher"
      ? ["All Batches", "All Lecture", "All Coupon"]
      : role == "moderator"
        ? moderatorAccess[0]?.value
        : []
  );
  // const [firstClick, setFirstClick] = useState({ name: '', click: true })
  // console.log('permission', permission)
  const handleMultiLevelSelect = (e) => {
    console.log(e);
    // e.preventDefault();
    // console.log('line268', e[0].options.length)
    const pushArray = (a) => {
      if (a?.options) {
        return a.options?.map((ii) => ii.value);
      } else {
        return a.value?.map((ii) => ii);
      }
    };

    const final = [];
    const pushNestedArray = (arr) => {
      // console.log('line276', arr)
      return arr?.map((item) => final.push(item));
    };
    const helperArray = [];
    // console.log(helperArray)
    e?.map((item, index) => {
      helperArray.push(pushArray(item));
    });
    helperArray?.map((item, index) => pushNestedArray(item));

    setPermission(final);
    console.log(e[0]);
    // console.log("permissions" + permission)
    for (let i = 0; i < e[0]?.options?.length; i++) {
      // console.log(e[0].options.length)
      if (e[0].options[i].label.slice(0, 4) === "Add ") {
        setSelected(true);
        setAccesForTab("readWrite");
        break;
      } else {
        setSelected(false);
        setAccesForTab("read");
      }
    }
  };

  useEffect(() => {
    setPermission(
      role == "teacher"
        ? ["All Batches", "All Lecture", "All Coupon"]
        : role == "moderator"
          ? moderatorAccess[0]?.value
          : []
    );
  }, [role]);
  const [batchid, setBatchId] = useState();
  //batch and lecture details
  //Lecture list
  const [lectureDetails, setLectureDetails] = useState();

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchUsers() {

    }
    fetchUsers();
  }, [batchid]);

  //Lecture list

  const [batchDetails, setBatchDetails] = useState();
  useEffect(() => {
    const token = localStorage.getItem("token");
    // async function fetchUsers() {
    //     const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getBatchesDetails`, {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Authorization: `Bearer ${token}`
    //         },

    //     });
    //     const responseJson = await fullResponse.json();
    //     setBatchDetails(responseJson.data);
    // }
    // fetchUsers()
  }, [batchid]);
  // console.log('batch details', batchDetails)
  // console.log('delete', deleteAccess)
  // console.log('lecture details', lectureDetails)

  const changeHandler = async (event) => {
    setFile(event.target?.files);
    const url = await getFileUrl(event.target?.files[0]);
    setProfilePhotoUrl(url);
    var fileName = document.getElementById("file").value.toLowerCase();
    if (fileName.endsWith(".pdf") || fileName.endsWith(".jpg")) {
      setRestrict("");
    } else {
      setRestrict("JPG Or PDF");
    }
    //console.log(file)
  };

  return (
    <>
      <Stack spacing={3} px={5} height={"auto"}>
        <Typography variant="h3">Add Staff</Typography>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack
            spacing={3}
            sx={{ width: "70%" }}
            minHeight={"110vh"}
            maxHeight={"auto"}
          >
            <RHFTextField name="name" label="Name" />
            <RHFTextField name="email" label="Email address" />
            <RHFTextField
              name="mobileNumber"
              label="Mobile No."
              type="number"
            />
            <RHFTextField name="role" label="Role" select>
              <MenuItem value="admin" onClick={() => setRole("admin")}>
                Admin
              </MenuItem>
              <MenuItem value="teacher" onClick={() => setRole("teacher")}>
                Teacher
              </MenuItem>
              <MenuItem value="subadmin" onClick={() => setRole("subadmin")}>
                Sub-Admin
              </MenuItem>

              {/* <MenuItem value="moderator" onClick={() => setRole("moderator")}>
                Moderators
              </MenuItem> */}
            </RHFTextField>

            <Stack direction="row" spacing={3}>
              <RHFTextField
                name="file"
                onChange={changeHandler}
                id="file"
                label="File"
                error={!file && val}
                helperText="Image Only"
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                // inputProps={{ multiple: true }}
                InputProps={{
                  classes: {
                    input: "CustomTextField",
                  },
                }}
                onClick={() => setPdf(true)}
                inputProps={{ accept: "image/*" }}
                type={pdf || focus ? "file" : "text"}
              />
            </Stack>

            {role !== "teacher" && (
              <RHFTextField
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <Iconify
                          icon={
                            showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}

            {/* Assign Subject only Visible to students */}
            {
              role == 'teacher' && <Stack direction="row">
                <InputLabel
                  sx={{
                    width: "20%",
                    marginRight: "20px",
                    alignSelf: "center",
                  }}
                >
                  Assign Subjects
                </InputLabel>
                <Select
                  // label    ="Name"
                  // id="demo-multiple-name"
                  // labelId="test-select-label"
                  label=""
                  multiple
                  name="subject"
                  value={teacher}
                  onChange={handleChange}
                  sx={{ width: "100%", color: "black", zIndex: 9 }}
                  MenuProps={MenuProps}
                >
                  {users?.map((name) => (
                    <MenuItem
                      key={name}
                      value={name.id}
                      style={getStyles(name, teacher, theme)}
                    >
                      {name.name}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            }


     
        

            {role !== "admin" && (
              <Stack direction="column" sx={{ width: "100%" }}>
                {role != "admin" && (
                  <Stack direction="row">
                    {" "}
                    <InputLabel
                      sx={{
                        width: "20%",
                        marginRight: "20px",
                        alignSelf: "center",
                      }}
                    >
                      Assign Tabs
                    </InputLabel>
                    <MultiLevelSelect
                      options={
                        role == "teacher"
                          ? options?.optionsForTeacher
                          : options?.options
                      }
                      className="p-2" //
                      placeholder=" "
                      onChange={(e) => handleMultiLevelSelect(e)}
                    />
                  </Stack>
                )}

                {!permissionValidation?.set && role == "subadmin" && (
                  <Typography color="red" sx={{ marginLeft: "150px" }}>
                    {" "}
                    {permissionValidation?.text}
                  </Typography>
                )}

          
              </Stack>
            )}

            {/* {permission?.length > 0 ||
              (role !== "admin" && ( */}
               {role!='admin'&& <FormControl
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "90px",
                    alignItems: "center",
                  }}
                >
                  <FormLabel id="demo-row-checkbox-group-label">
                    Access Level
                  </FormLabel>

                  <div>


                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={allAccess}
                          onChange={handleAllAccessChange}
                        />
                      }
                      label="All Access"
                    />
                    {!allAccess && (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={readOnly}
                              onChange={handleCheckboxChange("read")}
                            />
                          }
                          label="Read Access"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={readWrite}
                              onChange={handleCheckboxChange("write")}
                            />
                          }
                          label="Write Access"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={deleteAccess}
                              onChange={handleCheckboxChange("delete")}
                            />
                          }
                          label="Delete Access"
                        />
                      </>
                    )}

                  </div>
                </FormControl>}
              {/* ))} */}

      

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={loading}
              sx={{
                bgcolor: "#e6e6ff",
                color: "black",
                marginTop: "5%",
                "&:hover": { background: "#8080ff" },
              }}
            >
              Register
            </LoadingButton>
          </Stack>
        </FormProvider>
        <ToastContainer
          position="bottom-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Stack>
    </>
  );
}
