import { filter } from "lodash";
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from "react";
// import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  // Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
// components
import TableHead from "@mui/material/TableHead";

import Scrollbar from "../../../components/Scrollbar";

import SearchNotFound from "../../../components/SearchNotFound";
import {
  UserListHead,
  UserListToolbar,
  UserMoreMenu,
} from "../user";
// mock
// importusers ? from '../../../_mock/user';
import { useNavigate, useParams } from "react-router-dom";
import SyncLoader from "react-spinners/SyncLoader";
import Page from "../../../components/Page";

import { ToastContainer, toast } from "react-toastify";
// for Dialog box
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import axios from "axios"; // for axios request

import "./test.css";
import { CSVLink } from "react-csv"; // for CSV file download
// ----------------------------------------------------------------------

// const TABLE_HEAD = [
//   // { id: '', label: 'Selected', alignRight: false },
//   { id: "sno", label: "S.No", alignRight: false },
//   { id: "FullName", label: "Name", alignRight: false },
//   // { id: "Enrollment", label: "Enrollment No.", alignRight: false },
//   { id: "mobileNumber", label: "Phone No.", alignRight: false },
//   { id: "email", label: "Email", alignRight: false },
//   { id: "coupon", label: "Coupon", alignRight: false },
//   { id: "charges", label: "Charges", alignRight: false },
//   { id: "charges", label: "Assigned By", alignRight: false },

//   // { id: "username", label: "User From", alignRight: false },
//   { id: "view", label: "View", alignRight: false },
//   { id: "delete", label: "Unassigned", alignRight: false },
// ];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  console.log("Query ",query)
  if (query) {
    return filter(array, (_user) => {
      // console.log(_user)
      const studentNameMatch = _user?.StudentName?.toLowerCase()?.includes(
        query.toLowerCase()
      );
      const emailMatch = _user?.email
        ?.toLowerCase()
        ?.includes(query.toLowerCase());
      const phoneMatch = _user?.mobile_no?.toString().includes(query);
        // ?.toLowerCase()
     
      return studentNameMatch || emailMatch || phoneMatch;
    });
  }
  return stabilizedThis?.map((el) => el[0]);
}
function applySortFilter2(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => {
      // console.log(_user);
      const studentNameMatch = _user?.first_name
        ?.toLowerCase()
        ?.includes(query.toLowerCase());
      const emailMatch = _user?.email
        ?.toLowerCase()
        ?.includes(query.toLowerCase());
        console.log("User ",_user)
      const phoneMatch = _user?.mobile_no
        ?.includes(query);
      return studentNameMatch || emailMatch || phoneMatch;
    });
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function SubscriptionList() {
  const detailObject = JSON.parse(localStorage.getItem("detailObject"));
  const accessArr = detailObject?.access_level?.split(',');
  const role = localStorage.getItem('role');

  const shouldShowUnassigned = accessArr?.includes('delete') || role === 'Admin'; // Your condition here

const TABLE_HEAD = [
  // { id: '', label: 'Selected', alignRight: false },
  { id: "sno", label: "S.No", alignRight: false },
  { id: "FullName", label: "Name", alignRight: false },
  // { id: "Enrollment", label: "Enrollment No.", alignRight: false },
  { id: "mobileNumber", label: "Phone No.", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "coupon", label: "Coupon", alignRight: false },
  { id: "charges", label: "Charges", alignRight: false },
  { id: "assignedBy", label: "Assigned By", alignRight: false }, // Changed the key to be unique

  // { id: "username", label: "User From", alignRight: false },
  { id: "view", label: "View", alignRight: false },
  ...(shouldShowUnassigned ? [{ id: "unassigned", label: "Unassigned", alignRight: false }] : []),
];

  const [deleteConfirm, setDeleteConfirm] = useState(null);

  // Code for handling Dialog box
  const [showDownload, setShowDownload] = useState(false);
  const [download, setDownload] = useState(true);
  const [statusUser, setStatusUser] = useState();
  const [statusStaus, setStatusStatus] = useState();
  const [errorOTP, setErrorOtp] = useState();
  const [open1, setOpen1] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [ids,setIds]=useState([])
  const handleClickOpen3 = (index) => {
    setDeleteConfirm(index);
    setOpen3(true);
  };

  const handleClose3 = () => {
    setDeleteConfirm(null);
    setOpen3(false);
  };

  const handleConfirm3 = (id) => {
    removeStudent(id);
    handleClose3();
  };

  // For handling Dialong box
  const [allStudents, setAllStudents] = useState([]);
  const [page1, setPage1] = useState(0);
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const [open, setOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState([]);
  const [filterStudent, setFilterStudent] = useState("");
  const handleClickOpen = () => {
    handleClickOpenPlan()
  
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(parseInt(event.target.value, 10));
    setPage1(0);
  };

  const handleCheck = (event, id) => {
    const selectedIndex1 = selectedStudent.indexOf(id);
    let newSelected = [];
    if (selectedIndex1 === -1) {
      newSelected = newSelected.concat(selectedStudent, id);
    } else if (selectedIndex1 === 0) {
      newSelected = newSelected.concat(selectedStudent.slice(1));
    } else if (selectedIndex1 === selected.length - 1) {
      newSelected = newSelected.concat(selectedStudent.slice(0, -1));
    } else if (selectedIndex1 > 0) {
      newSelected = newSelected.concat(
        selectedStudent.slice(0, selectedIndex1),
        selectedStudent.slice(selectedIndex1 + 1)
      );
    }
    setSelectedStudent(newSelected);
  };

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("fullName");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();
  const [users, usersSet] = useState([]);
  const [testSeriesName, setTestSeriesName] = useState("");
  const [loading, setLoading] = useState(true);
const [plan,setPlan]=useState([])
  // for Dialong box All Students
  const filteredStudents = applySortFilter(
    allStudents ? allStudents : ["no"],
    getComparator(order, orderBy),
    filterStudent
  );
  const handleFilterStudentByName = (event) => {
    setFilterStudent(event.target.value);
  };

  //Batch Id
  const { id, active } = useParams();
  //console.log(testSeriesId)

  //Getting Details
  async function fetchUsers() {
    const token = localStorage.getItem("token");
    const fullResponse = await fetch(
      `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-enrolled-student-by-subscription/${id}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const responseJson = await fullResponse.json();
    usersSet(responseJson.data);
    setIds(() => {
      return responseJson?.data?.map((std) => std.id) || [];
    });
    toast.success("Student Fetched Successfully of Subscription")
    setTestSeriesName(responseJson?.subscription?.title);
    console.log(responseJson.data);
    setLoading(false);
    if (responseJson.msg === "not an admin") {
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("details");
      navigate("/");
    }
  }
  useEffect(() => {
  
  

    fetchUsers();
  }, [filterName]);

  // //console.log(users)
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // getting all student
  function maskPhoneNumber(phoneNumber) {
    if (!phoneNumber) return "NA";

    const firstDigit = phoneNumber[0];
    const lastDigit = phoneNumber[phoneNumber.length - 1];
    const maskedDigits = phoneNumber.slice(1, -1).replace(/\d/g, 'x');

    return `<span class="math-inline">\{firstDigit\}</span>{maskedDigits}${lastDigit}`;
}

function maskEmail(email) {
    if (!email) return "NA";

    const [username, domain] = email.split('@');
    const maskedUsername = username.slice(0, 3) + 'xxx' + username.slice(-3);

    return `<span class="math-inline">\{maskedUsername\}@</span>{domain}`;
}
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchStudents() {
      const fullResponse = await fetch(
        `${
          process.env.REACT_APP_LIVE_URL_NEW
        }/admin/get-user-list?page=${1}&limit=${50000}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseJson = await fullResponse.json();
      // console.log(responseJson.data);
      setAllStudents(responseJson?.data?.data);
      setAllStudents((prevStudents) =>
        prevStudents?.filter((std) => !ids.includes(std.id))
      );
      
      setLoading(false);
      if (responseJson.msg === "not an admin") {
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("details");
        navigate("/");
      }
    }

    fetchStudents();
  }, [filterName]);

  //   //console.log(allStudents);

  // Assigning to Student

  const handleAssign = () => {
    const token = localStorage.getItem("token");
    const authToken = token;

    const config = {
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };

    const data = {
      students: selectedStudent,
      subscription_id: id,
      subscription_plan_id: planId,
    };

    axios
      .post(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/assign-subscription`,
        data,
        config
      )
      .then((response) => {
        //console.log(response);
        toast.success("   Subscription Assigned Successfully ")

        handleClose();
        fetchUsers();
      })
      .catch((e) => {
        toast.error(e, { autoClose: false });
        handleClose();
        //console.log(e);
      });
    setSelectedStudent([]);
  
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  /**
   * Fetches all the plans for the given subscription id.
   * Makes a GET request to /admin/plans/:id
   * @param {string} id - The id of the subscription
   * @returns {Promise<void>}
   */
const fetchPlans=async ()=>{
  const token = localStorage.getItem("token");
  const authToken = token;
  const config = {
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  const response =await axios.get(`${process.env.REACT_APP_LIVE_URL_NEW}/admin/plans/${id}`,config);
  console.log("Data : ",response.data.data)
  if( response.status==200){
    setPlan(response.data.data);
    setPlanId(response?.data?.data[0]?.id)
  }
}
useEffect(()=>{
fetchPlans()
},[])
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    // console.log(filterName)
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users?.length) : 0;
  // one more filter for if testSeries which user not null
  const filteredTestSeries = users.filter((userObj) => userObj.user !== null);
  const filteredUsers = applySortFilter2(
    users ? users : ["no"],
    getComparator(order, orderBy),
    filterName
  );

  const isUserNotFound = filteredUsers.length === 0;

  const removeStudent = async (userId) => {
    const token = localStorage.getItem("token");

    // //console.log(userId)

    try {
      const response = await fetch(
        `${process.env.REACT_APP_LIVE_URL_NEW}/admin/remove-student-from-subscription`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            student_id: userId,
            subscription_id: id,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      
      }

      toast.success("Student Removed from Subscription ");
      fetchUsers();
      // setTimeout(() => window.location.reload(), 1000);
    } catch (error) {
      toast.success(error);
    }
  };
  const [openPlan,setOpenPlan]=useState(false)
  const [planId,setPlanId]=useState('')
  const handleClickOpenPlan = () => {
    setOpenPlan(true); // Open the dialog
  };

  const handleClosePlan = () => {
    setOpenPlan(false); // Close the dialog
  };

  const handlePlanChange = (event) => {
    setPlanId(event.target.value); // Update selected plan
  };

  return (
    <>
      <Dialog open={openPlan} onClose={handleClosePlan}>
        <DialogTitle>Select Subscription Plan</DialogTitle>
        <DialogContent>
          {/* Dropdown for selecting plan */}
          <Select
            value={planId}
            onChange={handlePlanChange}
            displayEmpty
            fullWidth
            sx={{ marginBottom: '16px' }}
          >
          {plan?.map((pl)=>{
            return  <MenuItem value={pl?.id}>{"₹" + pl.amount + " / " + pl.validity + " Day"}</MenuItem>
          })}
           
          </Select>

        
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClosePlan}>Cancel</Button>
          <Button
            onClick={() => {
              // Add your logic here to handle submission (e.g., saving data)
              handleClosePlan();
              setOpen(true);
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          {" "}
          <SyncLoader color="#8080ff" loading={loading} size={20} />
        </Stack>
      ) : (
        <Page title="Students">
          <Container sx={{ position: "absolute", minWidth: "100%" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={3}
            >
              <Typography variant="h3" gutterBottom>
                All Users Of the Subscription /{testSeriesName}
              </Typography>
              <Button
                variant="contained"
                disabled={active == "0" ? true : false}
                className="button1"
                sx={{
                  width: "20%",
                  alignSelf: "center",
                  background: "#e6e6ff",
                  color: "black",
                  "&:hover": { background: "#8080ff" },
                  BorderAllRounded: "10px",
                }}
                onClick={handleClickOpen}
              >
                Add Students
              </Button>
            </Stack>

            {/* code for dialog box */}
            <>
              <Dialog
                open={open}
                onClose={handleClose}
                className="assign_dialog"
              >
                <AppBar sx={{ position: "relative", background: "#e6e6ff" }}>
                  <Toolbar sx={{ width: "100%" }}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        width: "100%",
                        color: "black",
                      }}
                    >
                      <DialogTitle>Assign Students</DialogTitle>
                      <Stack direction="row" justifyContent="space-between">
                        <UserListToolbar
                          numSelected={selectedStudent.length}
                          filterName={filterStudent}
                          onFilterName={handleFilterStudentByName}
                          from="AssignStudent"
                        />
                        <IconButton
                          edge="start"
                          color="inherit"
                          onClick={handleClose}
                          aria-label="close"
                        >
                          <CloseIcon />
                        </IconButton>
                      </Stack>
                    </Stack>
                  </Toolbar>
                </AppBar>
                <DialogContent>
                  <Card>
                    <Scrollbar>
                      <TableContainer sx={{ minWidth: 1000 }}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell align="left">Selected</TableCell>
                              <TableCell align="left">Student Name</TableCell>
                              <TableCell align="left">Mobile No.</TableCell>
                              <TableCell align="left">Email</TableCell>
                              {/* <TableCell align="left">Username</TableCell> */}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {filteredStudents
                              .slice(
                                page1 * rowsPerPage1,
                                page1 * rowsPerPage1 + rowsPerPage1
                              )
                              ?.map((row, index) => {
                                const {
                                  id,
                                  _id,
                                  StudentName,
                                  email,
                                  username,
                                  mobileNumber,
                                  profilePhoto,
                                  userId,
                                } = row;
                                const isItemSelected1 =
                                  selectedStudent.indexOf(id) !== -1;
                                // debugger;
                                // console.log(row);
                                // row.sno = index + 1 + rowsPerPage1 * page1
                                return (
                                  <TableRow
                                    hover
                                    key={index}
                                    tabIndex={-1}
                                    role="checkbox"
                                    selected={isItemSelected1}
                                    aria-checked={isItemSelected1}
                                  >
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        checked={isItemSelected1}
                                        onChange={(event) => {
                                          handleCheck(event, id);
                                        }}
                                      />
                                    </TableCell>

                                    <TableCell
                                      component="th"
                                      scope="row"
                                      pl={2}
                                    >
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={2}
                                      >
                                        <Avatar
                                          alt={StudentName}
                                          src={profilePhoto}
                                          sx={{ width: 28, height: 28 }}
                                        />
                                        <Typography variant="subtitle2" noWrap>
                                          {row.first_name.concat(
                                            " ",
                                            row.last_name
                                          )}
                                        </Typography>
                                      </Stack>
                                    </TableCell>
                                    <TableCell align="left">
                                      {row.mobile_no ? row.mobile_no : "NA"}
                                    </TableCell>
                                    <TableCell align="left">{email}</TableCell>
                                      {/* <TableCell align="left">
                                      {maskPhoneNumber(row?.mobile_no)}</TableCell>
                                      <TableCell align="left">{maskEmail(email)}</TableCell> */}
                                    {/* <TableCell align="left">
                                      {username}
                                    </TableCell> */}
                                  </TableRow>
                                );
                              })}
                            {emptyRows > 0 && (
                              <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Scrollbar>
                    <TablePagination
                      rowsPerPageOptions={[10, 25]}
                      component="div"
                      count={allStudents?.length}
                      rowsPerPage={rowsPerPage1}
                      page={page1}
                      onPageChange={handleChangePage1}
                      onRowsPerPageChange={handleChangeRowsPerPage1}
                    />
                  </Card>
                </DialogContent>

                <hr></hr>

                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  m={3}
                >
                  <Button
                    onClick={handleAssign}
                    variant="contained"
                    className="button2"
                    sx={{
                      width: "20%",
                      alignSelf: "center",
                      background: "#e6e6ff",
                      color: "black",
                      "&:hover": { background: "#8080ff" },
                      BorderAllRounded: "10px",
                    }}
                  >
                    Assign
                  </Button>
                </Stack>
              </Dialog>
            </>

            <Card>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="flex-end"
                alignItems="center"
                sx={{ margin: "10px" }}
              >
                <UserListToolbar
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />

                {/* <ExportCSV csvData={users} fileName='Student Data' /> */}
                {/* <Button
                  variant="outlined"
                  onClick={() => {
                    handleClickOpen1();
                    sendOtp();
                    setShowDownload(true);
                  }}
                >
                  Export
                </Button> */}
              </Stack>

              {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}

              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={users?.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {filteredUsers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        ?.map((row, index) => {
                          const {
                            email,
                            username,
                            FullName,
                            mobileNumber,
                            profilePhoto,
                            userId,
                          } = row;

                          const isItemSelected =
                            selected.indexOf(FullName) !== -1;
                          // row.sno = index + 1 + rowsPerPage * page
                          return (
                            <TableRow
                              hover
                              key={row?.user?._id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              {/* <TableCell padding="checkbox">
                                                        <Checkbox checked={isItemSelected} onChange={(event) => handleClick(event, FullName)} />
                                                    </TableCell> */}
                              <TableCell>
                                <Typography variant="subtitle2" noWrap>
                                  {index + 1 + rowsPerPage * page}
                                </Typography>
                              </TableCell>

                              <TableCell component="th" scope="row" pl={2}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Avatar
                                    alt={row?.user?.FullName}
                                    src={profilePhoto}
                                    sx={{ width: 28, height: 28 }}
                                  />
                                  <Typography noWrap>
                                    {/* {row?.user?.FullName} */}
                                    {row.first_name.concat(" " + row.last_name)}
                                  </Typography>
                                </Stack>
                              </TableCell>

                              {/* <TableCell>
                                <Typography variant="subtitle2" noWrap>
                                  {row.id}
                                </Typography>
                              </TableCell> */}
                              <TableCell align="left">
                                {row?.mobile_no ? row?.mobile_no : "NA"}
                              </TableCell>
                              <TableCell align="left">{row?.email}</TableCell>
                              <TableCell align="left">
                                {row?.coupon ? row.coupon : "N/A"}
                              </TableCell>
                             
                              <TableCell align="left">
                                {row?.assigned_by==1 ?0 : row?.price}
                              </TableCell>
                              <TableCell align="left">
                                {row?.assigned_by==1 ?"Admin" : "User"}
                              </TableCell>
                              {/* <TableCell align="left">
                                {row?.user_from}
                              </TableCell> */}
                              <TableCell align="left">
                                <Button
                                  variant="outlined"
                                  size="small"
                                  color="error"
                                  onClick={() =>
                                    navigate(
                                      `/dashboard/students/studentsProfile/${row?.user_id}`
                                    )
                                  }
                                >
                                  view
                                </Button>
                              </TableCell>

                              {shouldShowUnassigned && <TableCell align="left">
                            
                                {deleteConfirm === index ? (
                                  <>
                                    <Dialog open={open3} onClose={handleClose3}>
                                      <DialogTitle>
                                        {"Confirm Deletion"}
                                      </DialogTitle>
                                      <DialogContent>
                                        Are you sure you want to unassigned the
                                        course to this student?
                                      </DialogContent>
                                      <DialogActions>
                                        <Button
                                          onClick={() => handleConfirm3(row.user_id)}
                                          color="error"
                                        >
                                          YES
                                        </Button>
                                        <Button
                                          onClick={handleClose3}
                                          color="primary"
                                        >
                                          NO
                                        </Button>
                                      </DialogActions>
                                    </Dialog>
                                  </>
                                ) : (
                                  <Button
                                    variant="outlined"
                                    size="small"
                                    color="error"
                                    onClick={() => {
                                      handleClickOpen3(index);
                                    }}
                                  >
                                    Unassigned
                                  </Button>
                                )}
                              </TableCell>}
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>

                    {isUserNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <SearchNotFound searchQuery={filterName} />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={users?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Card>
            <ToastContainer
              position="bottom-center"
              autoClose={1000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </Container>
        </Page>
      )}
    </>
  );
}
