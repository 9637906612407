// component
import { useContext } from "react";
import Iconify from "../../components/Iconify";
const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
const role = localStorage.getItem("role");
const detailObject = JSON.parse(localStorage.getItem("detailObject"));
let accessTab = detailObject?.accessToContent;
console.log(accessTab, accessTab?.includes("All Quiz"));
let adminNavConfig = [
  {
    title: "dashboard",
    path: "/dashboard/app",
    icon: getIcon("eva:pie-chart-2-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  },
  {
    title: "Users",
    path: "#",
    icon: getIcon("eva:people-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
    subNav: [
      {
        title: "All Users",
        path: "/dashboard/students",
        navicon: "ph:student",
        cName: "sub-nav",
      },
      //! temperory comment of purchases
      {
        title: "Purchases",
        path: "/dashboard/studentBatches",
        navicon: "ic:baseline-support-agent",
        cName: "sub-nav",
      },

      // {
      //   title: "Purchase Report",
      //   path: "/dashboard/purchaseReport",
      //   navicon: "ant-design:file-add-outlined",
      //   cName: "sub-nav",
      // },
      // {
      //   title: "User Report",
      //   path: "/dashboard/userReport",
      //   navicon: "ant-design:file-add-outlined",
      //   cName: "sub-nav",
      // },
    ],
  },
  {
    title: "Courses",
    path: "#",
    icon: getIcon("eva:shopping-bag-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),

    subNav: [
      {
        title: "All Courses",
        path: "/dashboard/batches",
        navicon: "material-symbols:batch-prediction",
        cName: "sub-nav",
      },
      {
        title: "Add Course",
        path: "/dashboard/addBatch",
        navicon: "bxs:message-square-add",
        cName: "sub-nav",
      },
      {
        title: "Add Lecture",
        path: "/dashboard/addCenterLecture",
        navicon: "bxs:message-square-add",
        cName: "sub-nav",
      },
      // {
      //   title: "Add Lecture Resource",
      //   path: "/dashboard/addCenterResource",
      //   navicon: "bxs:message-square-add",
      //   cName: "sub-nav",
      // },
    ],
  },
  {
    title: "Test Series",
    path: "#",
    icon: getIcon("eva:file-text-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
    subNav: [
      {
        title: "All Test Series",
        path: "/dashboard/test",
        navicon: "akar-icons:book-close",
        cName: "sub-nav",
      },
      {
        title: "Add Test Series",
        path: "/dashboard/addTest",
        navicon: "ant-design:file-add-outlined",
        cName: "sub-nav",
      },
    ],
  },
  {
    title: "Mock Test",
    path: "#",
    icon: getIcon("eva:file-text-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
    subNav: [
      {
        title: "All Mock Test",
        path: "/dashboard/mockTest",
        navicon: "akar-icons:book-close",
        cName: "sub-nav",
      },
      {
        title: "Add Manual Test",
        path: "/dashboard/manual",
        navicon: "ant-design:file-add-outlined",
        cName: "sub-nav",
      },
      {
        title: "Add Template Test",
        path: "/dashboard/template",
        navicon: "ant-design:file-add-outlined",
        cName: "sub-nav",
      },
    ],
  },
  //!mock test comment out
  //  {
  //    title: "Mock Test",
  //   path: "#",
  //    icon: getIcon("material-symbols:quiz-outline"),
  //    dropIcon: getIcon("gridicons:dropdown"),
  //   upIcon: getIcon("mi:caret-up"),
  //    subNav: [
  //    {
  //       title: "All Mock Test",
  //       path: "/dashboard/quiz",
  //        navicon: "akar-icons:book-close",
  //        cName: "sub-nav",
  //     },
  //     {
  //       title: "Add Mock Test",
  //      path: "/dashboard/addQuiz",
  //       navicon: "ant-design:file-add-outlined",
  //      cName: "sub-nav",
  //    },
  //   ],
  //  },
  // {
  //   title: "Coupon",
  //   path: "/dashboard/allCoupon",
  //   icon: getIcon("ri:coupon-3-line"),
  //   dropIcon: getIcon("gridicons:dropdown"),
  //   upIcon: getIcon("mi:caret-up"),
  //   // subNav: [
  //   //   {
  //   //     title: "All Coupon",
  //   //     path: "/dashboard/allCoupon",
  //   //     navicon: "akar-icons:book-close",
  //   //     cName: "sub-nav",
  //   //   },
  //   //   // {
  //   //   //   title: "Add Coupon",
  //   //   //   path: "/dashboard/addCoupon",
  //   //   //   navicon: "ant-design:file-add-outlined",
  //   //   //   cName: "sub-nav",
  //   //   // },
  //   // ],
  // },
  {
    title: "Resources",
    path: "#",
    icon: getIcon("eva:alert-triangle-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
    subNav: [
      //     {
      //       title: "Recorded Video",
      //       path: "/dashboard/recorded",
      //       navicon: "carbon:course",
      //       cName: "sub-nav",
      //     },
      {
        title: "Syllabus",
        path: "/dashboard/Pathyakram",
        navicon: "carbon:course",
        cName: "sub-nav",
      },

      {
        title: "Audios",
        path: "/dashboard/audio",
        navicon: "ri:file-settings-line",
        cName: "sub-nav",
      },

      {
        title: "Demo Videos",
        path: "/dashboard/youtube",
        navicon: "ri:file-settings-line",
        cName: "sub-nav",
      },
      //     // {
      //     //   title: "All Resources",
      //     //   path: "/dashboard/resources",
      //     //   navicon: "carbon:course",
      //     //   cName: "sub-nav",
      //     // },
      //     // {
      //     //   title: "Add Resources",
      //     //   path: "/dashboard/addResources  ",
      //     //   navicon: "carbon:course",
      //     //   cName: "sub-nav",
      //     // },
      //     {
      //       title : "Previous Year Question Papers",
      //       path:"/dashboard/previousYearQuestionPapers",
      //       navicon:"carbon:course",
      //       cName:"sub-nav",
      //     },
      {
        title: "Notes",
        path: "/dashboard/notes",
        navicon: "charm:notes",
        cName: "sub-nav",
      },
      //     {
      //       title: "AIR",
      //       path: "/dashboard/air",
      //       navicon: "carbon:radio",
      //     },
      //!News  clip comment out
      //  {
      //   title: "News Clip",
      //   path: "/dashboard/newsClip",
      //    navicon: "wpf:news",
      //  },
      //     {
      //       title: "Teacher Generated Content of Batch",
      //       path: "/dashboard/teacherGenerated",
      //       navicon: "bx:book-content",
      //     },
      //     {
      //       title: "Alerts",
      //       path: "/dashboard/customNotification",
      //       navicon: "carbon:course",
      //       cName: "sub-nav",
      //     }
    ],
  },

  {
    title: "Subscriptions",
    icon: getIcon("mdi:subscriptions"),
    path: "/dashboard/subscription",
    navicon: "carbon:course",
    cName: "nav",
  },

  // {
  //   title: "Announcements",
  //   icon: getIcon("mdi:announcement"),
  //   path: "/dashboard/customNotification",
  //   navicon: "carbon:course",
  //   // cName: "nav",
  // },
  {
    title: "Notification",
    icon: getIcon("mdi:announcement"),
    path: "/dashboard/notification",
    navicon: "carbon:course",
    // cName: "nav",
  },
  // {
  //   title: "Assignment",
  //   path: "/dashboard/assignment",
  //   icon: getIcon("eva:book-fill"),
  //   dropIcon: getIcon("gridicons:dropdown"),
  //   upIcon: getIcon("mi:caret-up"),
  // },
  {
    title: "Manage Staff",
    path: "/dashboard/staff",
    icon: getIcon("ic:outline-manage-accounts"),
    dropIcon: getIcon("mi:caret-down"),
    upIcon: getIcon("mi:caret-up"),
  },
  // {
  //   title: "Testimonial",
  //   path: "/dashboard/testimonial",
  //   icon: getIcon("dashicons:testimonial"),
  //   dropIcon: getIcon("gridicons:dropdown"),
  //   upIcon: getIcon("mi:caret-up"),
  // },

  // {
  //   title: "Banner",
  //   path: "/dashboard/banner",
  //   icon: getIcon("ri:file-settings-line"),
  //   // cName: "sub-nav",
  // },

  // {
  //   title: " Coupon",
  //   path: "/dashboard/allCoupon",
  //   icon: getIcon("akar-icons:book-close"),
  //   // cName: "nav",
  // },

  // {
  //   title: "Subject",
  //   path: "/dashboard/subject",
  //   icon: getIcon("ri:file-settings-line"),
  //   // cName: "sub-nav",
  // },
  
  {
    title: "General Settings",
    path: "#",
    icon: getIcon("ci:settings"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),

    subNav: [
      {
        title: "Exam Groups",
        path: "/dashboard/exam-groups",
        navicon: "ri:file-settings-line",
        icon: getIcon("akar-icons:book-close"),
        cName: "sub-nav",
      },

      {
        title: "Banner",
        path: "/dashboard/banner",
        navicon: "ri:file-settings-line",
        icon: getIcon("akar-icons:book-close"),
        cName: "sub-nav",
      },

      {
        title: " Coupon",
        path: "/dashboard/allCoupon",
        icon:getIcon("akar-icons:book-close"),
        navicon: "akar-icons:book-close",
        cName: "sub-nav",
      },
      // {
      //   title: "Logout Users",
      //   path: "/dashboard/logoutUsers",
      //   navicon: "ant-design:logout-outlined",
      //   cName: "sub-nav",
      // },
      //! comment subject
      {
        title: "Subject",
        path: "/dashboard/subject",
        icon: getIcon("ri:file-settings-line"),
        navicon: "ri:file-settings-line",
        cName: "sub-nav",
      },
      // {
      //   title: "Feedback",
      //   path: "/dashboard/feedback",
      //   navicon: "ri:file-settings-line",
      //   cName: "sub-nav",
      // },
      // {
      //   title: "Youtube",
      //   path: "/dashboard/youtube",
      //   navicon: "ri:file-settings-line",
      //   cName: "sub-nav",
      // },
      // {
      //   title: "Telegram",
      //   path: "/dashboard/telegram",
      //   navicon: "ri:file-settings-line",
      //   cName: "sub-nav",
      // },
      //! category comment out
      // {
      //   title: "Category",
      //   path: "/dashboard/category",
      //   navicon: "ic:outline-category",
      //   cName: "sub-nav",
      // },
      // {
      //   title :"Sub Category",
      //   path : "/dashboard/subCategory",
      //   navicon : "ic:outline-category",
      //   cName:"sub-nav",
      // },
      // {
      //   title: "Contact Us",
      //   path: "/dashboard/contactUs",
      //   navicon: "cil:contact",
      //   cName: "sub-nav",
      // },
      //!temperory
      // {
      //   title: "Help and Support",
      //   path: "/dashboard/message",
      //   navicon: "ic:baseline-support-agent",
      // },
    ],
  },

];

let batchesheading = {};
if (
  accessTab?.includes("All Batches") ||
  accessTab?.includes("Add Batch") ||
  accessTab?.includes("Add Center Lecture") ||
  accessTab?.includes("Add Center Resource")
) {
  batchesheading = {
    title: "Courses",
    path: "#",
    icon: getIcon("eva:shopping-bag-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  };
}
let testseriesheading = {};
if (
  accessTab?.includes("All Test Series") ||
  accessTab?.includes("Add Test Series")
) {
  //console.log('came in here test ')
  testseriesheading = {
    title: "Test Series",
    path: "#",
    icon: getIcon("eva:file-text-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  };
}
let mocktestheading = {};
if (
  accessTab?.includes("All Mock Test") ||
  accessTab?.includes("Add Manual Test") ||
  accessTab?.includes("Add Template Test")
) {
  //console.log('came in here test ')
  mocktestheading = {
    title: "Mock Test",
    path: "#",
    icon: getIcon("eva:file-text-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  };
}

let quizheading = {};
// if (accessTab?.includes('All Quiz') || accessTab?.includes('Add Quiz')) {
//   //console.log('came in here test ')
//   quizheading = {
//     title: 'Quiz',
//     path: '#',
//     icon: getIcon('material-symbols:quiz-outline'),
//     dropIcon: getIcon('gridicons:dropdown'),
//     upIcon: getIcon('mi:caret-up'),
//   }
// }

let resourcesheading = {};
if (
  accessTab?.includes("Recorded Video") ||
  accessTab?.includes("Pathyakram") ||
  accessTab?.includes("Notes") ||
  accessTab?.includes("AIR") ||
  accessTab?.includes("Teacher Generated Content of Batch") ||
  accessTab?.includes("News Clip")
) {
  resourcesheading = {
    title: "Resources",
    path: "#",
    icon: getIcon("eva:alert-triangle-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  };
}
let generalsettings = {};
if (
  accessTab?.includes("Banner") ||
  accessTab?.includes("Coupon") ||
  accessTab?.includes("Logout Users") ||
  accessTab?.includes("Category") ||
  accessTab?.includes("Telegram") ||
  accessTab?.includes("Youtube") ||
  accessTab?.includes("Subject") ||
  accessTab?.includes("Contact Us") ||
  accessTab?.includes("Help and Support")
) {
  generalsettings = {
    title: "General Settings",
    path: "#",
    icon: getIcon("ci:settings"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  };
} else if (accessTab?.includes("all")) {
  generalsettings = {
    title: "General Settings",
    path: "#",
    icon: getIcon("ci:settings"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  };
}

let assignment = {};
if (accessTab?.includes("Assignment")) {
  assignment = {
    title: "Assignment",
    path: "/dashboard/assignment",
    icon: getIcon("eva:book-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  };
}
let allbatches = {};
if (accessTab?.includes("All Batches")) {
  allbatches = {
    title: "All Courses",
    path: "/dashboard/batches",
    navicon: "material-symbols:batch-prediction",
    cName: "sub-nav",
  };
}
let addbatches = {};
if (accessTab?.includes("Add Batch")) {
  addbatches = {
    title: "Add Course",
    path: "/dashboard/addBatch",
    navicon: "bxs:message-square-add",
    cName: "sub-nav",
  };
}
// let addCenterLectures = {};
// if (accessTab?.includes('Add Center Lecture')) {
//   // console.log(accessTab);
//   addCenterLectures = {
//     title: "Add Lecture",
//     path: "/dashboard/addCenterLecture",
//     navicon: 'bxs:message-square-add',
//     cName: "sub-nav",
//   }
//   // console.log({...addCenterLecture});
// }
let testseries = {};
if (accessTab?.includes("All Test Series")) {
  testseries = {
    title: "All Test Series",
    path: "/dashboard/test",
    navicon: "akar-icons:book-close",
    cName: "sub-nav",
  };
}
let addtestseries = {};
if (accessTab?.includes("Add Test Series")) {
  addtestseries = {
    title: "Add Test Series",
    path: "/dashboard/addTest",
    navicon: "ant-design:file-add-outlined",
    cName: "sub-nav",
  };
}
let quiz = {};
if (accessTab?.includes("All Quiz")) {
  quiz = {
    title: "All Quiz",
    path: "/dashboard/quiz",
    navicon: "akar-icons:book-close",
    cName: "sub-nav",
  };
}
let addquiz = {};
if (accessTab?.includes("Add Quiz")) {
  addquiz = {
    title: "Add Quiz",
    path: "/dashboard/addQuiz",
    navicon: "ant-design:file-add-outlined",
    cName: "sub-nav",
  };
}
let recordedvideos = {};
if (accessTab?.includes("Recorded Video")) {
  recordedvideos = {
    title: "Recorded Video",
    path: "/dashboard/recorded",
    navicon: "carbon:course",
    cName: "sub-nav",
  };
}
let Pathyakram = {};
if (accessTab?.includes("Pathyakram")) {
  Pathyakram = {
    title: "Syllabus",
    path: "/dashboard/Pathyakram",
    navicon: "carbon:course",
    cName: "sub-nav",
  };
}
let notes = {};
if (accessTab?.includes("Notes")) {
  notes = {
    title: "Notes",
    path: "/dashboard/notes",
    navicon: "charm:notes",
    cName: "sub-nav",
  };
}
let air = {};
if (accessTab?.includes("AIR")) {
  air = {
    title: "AIR",
    path: "/dashboard/air",
    navicon: "carbon:radio",
  };
}
let newsclip = {};
// if (accessTab?.includes('News Clip')) {
//   newsclip = {
//     title: "News Clip",
//     path: "/dashboard/newsClip",
//     navicon: 'wpf:news',
//   }
// }
let teachercontent = {};
if (accessTab?.includes("Teacher Generated Content of Batch")) {
  teachercontent = {
    title: "Teacher Generated Content of Batch",
    path: "/dashboard/teacherGenerated",
    navicon: "bx:book-content",
  };
}

let banner = {};
if (accessTab?.includes("Banner")) {
  banner = {
    title: "Banner",
    path: "/dashboard/banner",
    navicon: "ri:file-settings-line",
    cName: "sub-nav",
  };
}


// Older SubadminNavConfig --->i saw NavConfigForTeacher is being used for subadmin so i am moving with it ..
let SubAdminnavConfig = [
  {
    title: "dashboard",
    path: "/dashboard/app",
    icon: getIcon("eva:pie-chart-2-fill"),
    dropIcon: getIcon("gridicons:dropdown"),
    upIcon: getIcon("mi:caret-up"),
  },
  {
    ...batchesheading,
    subNav: [
      {
        ...allbatches,
      },
      {
        ...addbatches,
      },
    ],
  },
  {
    ...testseriesheading,
    subNav: [
      {
        ...testseries,
      },
      {
        ...addtestseries,
      },
    ],
  },
  {
    ...quizheading,
    subNav: [
      {
        ...quiz,
      },
      {
        ...addquiz,
      },
    ],
  },
  // {
  //   ...resourcesheading,
  //   subNav: [
  //     {
  //       ...recordedvideos
  //     },
  //     {
  //       ...Pathyakram
  //     },
  //     // {
  //     //   title: "All Resources",
  //     //   path: "/dashboard/resources",
  //     //   navicon: 'carbon:course',
  //     //   cName: "sub-nav",
  //     // },
  //     // {
  //     //   title: "Add Resources",
  //     //   path: "/dashboard/addResources  ",
  //     //   navicon: 'carbon:course',
  //     //   cName: "sub-nav",
  //     // },
  //     {
  //       ...notes
  //     },
  //     {
  //       ...air
  //     },
  //     {
  //       ...newsclip
  //     },
  //     {
  //       ...teachercontent
  //     },

  //   ]
  // },
  // {
  //   ...assignment
  // },
  {
    ...generalsettings,
    subNav: [
      {
        ...banner,
      },
    ],
  },
];


// Now Modified according to admin

let navConfig;
if (role === "Admin") {
  navConfig = adminNavConfig;
} else if (role === "subadmin") {
  navConfig = SubAdminnavConfig.filter((element) => {
    return "title" in element && "path" in element && "icon" in element;
  });
}

export default navConfig;
