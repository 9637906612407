import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import { Box, Button, Checkbox, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Page from "../../../components/Page";
import { useNavigate, useParams } from "react-router-dom";
// import TestCard from "./components/TestCard";
import TestSeriesDetailsCard from "./components/TestSeriesDetailsCard";
import SyncLoader from "react-spinners/SyncLoader";
import { UserMoreMenu } from "../user";

import axios from "axios";
import { toast } from "react-toastify";

const TestSeriesDetails = () => {
  const detailObject = JSON.parse(localStorage.getItem("detailObject"));
  const accessArr = detailObject?.access_level?.split(',');
  const contentAccessArr = detailObject?.accessToContent;
  const role = localStorage.getItem('role');
  const access = JSON.parse(localStorage.getItem("detailObject"));

  const navigate = useNavigate();
  const { testSeriesId } = useParams();

  //Loading
  const [loading, setLoading] = useState(true);

  //Test Info
  const [test, setTest] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelect = (id) => {
    setSelectedItems((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((itemId) => itemId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelecteds = test?.map((n) => n.id);
      setSelectedItems(newSelecteds);
      setSelectAll(true);
      return;
    }
    setSelectedItems([]);
    setSelectAll(false);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchtestSeriesDetails() {
      // const fullResponse = await fetch(`${process.env.REACT_APP_LIVE_URL}/adminPanel/getTestForTestSeriesDetails?TestSeries_id=${testSeriesId}`, {
      //     method: 'GET',
      //     headers: {
      //         'Content-Type': 'application/json',
      //         Authorization: `Bearer ${token}`
      //     },
      // });

      // const responseJson = await fullResponse.json();
      // setLoading(false)
      // setTest(responseJson.data);
      axios
        .get(
          `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-test-by-testseries/${testSeriesId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          setTest(res.data.data);
          console.log(res);
        });
    }
    fetchtestSeriesDetails();
  }, []);

  const [testseriesName, setTestSeriesName] = useState("");
  useEffect(() => {
    const token = localStorage.getItem("token");
    async function fetchtestSeriesname() {
      axios
        .get(
          `${process.env.REACT_APP_LIVE_URL_NEW}/admin/get-testseries/${testSeriesId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          setTestSeriesName(res?.data?.data?.name);
          console.log(res?.data?.data?.name);
        });
    }
    fetchtestSeriesname();
  }, []);

  //console.log(testSeriesId)

  const handleDeleteSelected = async () => {
    const token = localStorage.getItem("token");
    console.log([...selectedItems]);
    // const res = await axios.delete(
    //   `${process.env.REACT_APP_LIVE_URL_NEW}/admin/delete-bulk-banner`,
    //   {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   },
    //   { ids: [...selectedRows] }
    // );
    // console.log(res);
    const res = await fetch(
      `${process.env.REACT_APP_LIVE_URL_NEW}/admin/delete-bulk-test`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ ids: [...selectedItems] }),
      }
    );
    const fullres = res.json();
    // toast.success("Delete successfully");
    setTimeout(() => {
      window.location.reload();
    }, 1500);

    console.log(res);
  };

  return (
    <>
      {loading ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <SyncLoader color=" #8080ff" loading={loading} size={20} />
        </Stack>
      ) : (
        <Page title="Test Series">
          <Grid container pl={5} pr={3} spacing={4}>
            <Grid item xs={12} sm={6} md={8}>
              <Stack
                direction="row"
                mb={5}
                justifyContent="space-between"
                sx={{ width: "100%" }}
              >
                <Typography variant="h3">
                  All Test {`(${testseriesName})`}
                </Typography>
                {(role=='Admin'||contentAccessArr?.includes("Add Test Series")) && (
                  <Button
                    variant="contained"
                    sx={{
                      width: "20%",
                      alignSelf: "center",
                      background: "#e6e6ff",
                      color: "black",
                      "&:hover": { background: " #8080ff" },
                    }}
                    onClick={() =>
                      navigate(
                        `/dashboard/test/testSeriesDetails/addTest/${testSeriesId}`
                      )
                    }
                  >
                    Add Test
                  </Button>
                )}
              </Stack>
              {
                (role=='Admin'||accessArr?.includes('delete'))&&
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack direction={"row"} alignItems={"center"}>
                  <Checkbox
                    checked={selectAll}
                    onChange={handleSelectAll}
                    indeterminate={
                      selectedItems.length > 0 &&
                      selectedItems.length < test.length
                    }
                  />
                  <Typography>Select All</Typography>
                </Stack>
             
                {selectedItems.length > 0 && (
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleDeleteSelected}
                    style={{ margin: "10px" }}
                  >
                    Delete Selected Test
                  </Button>
                )}
              </Stack>
            }
           
 <Box sx={{ overflowX: 'auto', maxWidth: '100%' }}>
              <Table>
  <TableHead>
    <TableRow>
    {
      (role=='Admin'||accessArr?.includes('delete'))&&  <TableCell> </TableCell>
    }
      <TableCell>Test Name</TableCell>
      <TableCell>Subjects/Count</TableCell>
      <TableCell>Duration</TableCell>
      <TableCell>Marking Y/N </TableCell>
      <TableCell>Buttons</TableCell>
      <TableCell>Actions</TableCell>
    </TableRow>
  </TableHead>

  <TableBody>
    {test?.length !== 0 ? (
      test?.map((item, index) => (
        <TableRow key={index} onClick={() => handleSelect(item.id)}>
        {
          (role=='Admin'||accessArr?.includes('delete'))&&  <TableCell>
            <Checkbox checked={selectedItems.includes(item.id)} />
          </TableCell>
        }
          <TableCell>
            <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
              {item?.title}
            </Typography>
          </TableCell>
          <TableCell>
          <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
          {item?.subject_id?.map((data) => (
    <span key={data.id}>
      {data.subject_name} <span style={{ color: 'violet' }}>({data.count})</span>
    </span>
  )).reduce((prev, curr) => [prev, ' || ', curr])}
</Typography>

          </TableCell>
         
          <TableCell>
            <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
              {item?.duration}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography sx={{ fontSize: "18px", fontWeight: "400" }}>
              {item?.nagetiveMarking === "1"||item?.nagetiveMarking === "true"
                ? `Y (${item?.nagetiveMarks})`
                : "N"}
            </Typography>
          </TableCell>
          <TableCell sx={{alignItems:"center"}}>
            <Button
              variant="contained"
              sx={{
                minWidth: "100",
                maxWidth: "100",
                background: "#e6e6ff",
                color: "black",
                marginBottom:"10px",
                borderRadius: "10px",
                "&:hover": { background: " #8080ff" },
              }}
              onClick={() =>
                navigate(`/dashboard/quiz/${item?.title}/${item?.id}`)
              }
            >
              Questions
            </Button>
         
            <Button
              variant="contained"
              sx={{
                minWidth: "100",
                maxWidth: "100",
                background: "#e6e6ff",
                color: "black",
                borderRadius: "10px",
                "&:hover": { background: " #8080ff" },
              }}
              onClick={() =>
                navigate(
                  `/dashboard/quiz/quizStudents/${item?.title}/${item?.id}`
                )
              }
            >
              Results
            </Button>
          </TableCell>
          <TableCell>
            <Box sx={{ position: "relative", right: "1%" }}>
              <UserMoreMenu
                title="TestDetails"
                req="delete-test"
                id={item?.id}
                setLoading={setLoading}
                testSeriesId={testSeriesId}
                testId={item?.id}
              />
            </Box>
          </TableCell>
        </TableRow>
      ))
    ) : (
      <TableRow>
        <TableCell colSpan={8}>
          <Typography variant="h4" align="center">
            No Tests
          </Typography>
        </TableCell>
      </TableRow>
    )}
  </TableBody>
</Table>
</Box>

            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TestSeriesDetailsCard testSeriesId={testSeriesId} />
            </Grid>
          </Grid>
        </Page>
      )}
    </>
  );
};

export default TestSeriesDetails;
