import { MenuItem, Stack } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react'
import { RHFTextField } from '../components/hook-form';

export default function CategoryComponent({
    selectedCategory, setSelectedCategory,
    classVal, setClass,
    centralExam, setCentralExam,
    stateExam, setStateExam,
    selectedState, setSelectedState,
    selectedBoard, setSelectedBoard,width
}) {


    const [subCategories, setSubCategories] = useState([]);
    const [states, setStates] = useState(); 
    const [boards, setBoards] = useState();
    const [isCategoryLoading, setIsCategoryLoading] = useState(false);


    const streamCategory = [
        {
          id:"class",
          title:"School Exams"
        },
        {
          id:"central-exam",
          title:"Central Exams"
        },
        {
          id:"state-exam",
          title:"State Exams"
        },
      ]


      const handleCategoryChange = async (event) => {
        const selectedCategoryId = event.target.value;
        // console.log(selectedCategoryId);
        // console.log(event.target.value);
        setStates(null);
        setBoards(null);
        setSubCategories(null);
        setSelectedBoard(null);
        setSelectedState(null);
        
        setIsCategoryLoading(true);
        setSelectedCategory(selectedCategoryId);
    
        const token = localStorage.getItem("token");
        switch (selectedCategoryId) {
          case 'central-exam':
            const centralExams = await axios.post(`${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-central-exams`, {
              headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            }});
            setSubCategories(centralExams?.data?.data);
            break;
    
          case 'state-exam':
            const stateList = await axios.post(`${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-states`, {
              headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            }});
            console.log(stateList?.data?.data)
            setStates(stateList?.data?.data);
            break;
          
          case 'class':
            const boardsList = await axios.post(`${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-boards`, {
              headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            }});
            // console.log(boardsList?.data?.data)
            setBoards(boardsList?.data?.data);
            break;
    
          default:
            break;
        }
        // Fetch subcategories based on the selected category
        setIsCategoryLoading(false);
       
      };


      const handleStateChange = async(e)=>{
        setIsCategoryLoading(true);
        setSelectedState(e.target.value);
        const currentState = e.target.value;
        setSubCategories(null);
        const stateExamsList = await axios.post(`${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-state-exams`, {state_exam_id: currentState})
        console.log(stateExamsList.data)
        setSubCategories(stateExamsList.data?.data); 
        setIsCategoryLoading(false);
      }


      const handleBoardChange = async(e)=>{
        setIsCategoryLoading(true);
        const currentBoard = e.target.value;
        setSelectedBoard(e.target.value);
        setSubCategories(null);
        const boardExamsList = await axios.post(`${process.env.REACT_APP_LIVE_URL_NEW}/mobile/get-classes`, {board_id: currentBoard})
        console.log(boardExamsList.data)
        setSubCategories(boardExamsList.data?.data); 
        setIsCategoryLoading(false);
      }


      const handleSubCategoryChange = (e)=>{
        setClass(null);
        setStateExam(null);
        setCentralExam(null);
    
        if(boards) setClass(e.target.value);
        else if(states) setStateExam(e.target.value);
        else setCentralExam(e.target.value);
    
        console.log("boards", classVal);
        console.log("states", stateExam);
        console.log("central", centralExam);
    
      }



  return (
    <Stack direction={"row"} spacing={3} width={width}>
                <RHFTextField
                  name="category"
                  label="Category"
                  disabled={isCategoryLoading}
                  sx={{ width: "50%" }}
                  select
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                >
                  {streamCategory?.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      {item.title}
                    </MenuItem>
                  ))}
                </RHFTextField>

                {states && <RHFTextField
                  name="states"
                  label="State"
                  disabled={isCategoryLoading}
                  sx={{ width: "50%" }}
                  select
                  value={selectedState}
                  onChange={handleStateChange}
                >
                  {states?.map((state, index) => (
                    <MenuItem key={index} value={state.id}>
                      {state.name}
                    </MenuItem>
                  ))}
                </RHFTextField>}

                {boards && <RHFTextField
                  name="boards"
                  label="Boards"
                  disabled={isCategoryLoading}
                  sx={{ width: "50%" }}
                  select
                  value={selectedBoard}
                  onChange={handleBoardChange}
                >
                  {boards?.map((board, index) => (
                    <MenuItem key={index} value={board.id}>
                      {board.name}
                    </MenuItem>
                  ))}
                </RHFTextField>}

                <RHFTextField
                  name="subCategory"
                  label="Subcategory"
                  disabled={isCategoryLoading}
                  sx={{ width: "50%" }}
                  select
                  onChange={handleSubCategoryChange}
                >
                  {/* //!This subcategory is working for all categories simultaneously */}

                  {subCategories?.map((subcategory, index) => (
                    <MenuItem key={index} value={subcategory.id}>
                      {subcategory?.name || subcategory?.class_name} 
                    </MenuItem>
                  ))}
                </RHFTextField>
              </Stack>
  )
}
